import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

import SideBar from "../../../components/SideBar";
import { Pagination } from "../../../components/Pagination";
import InternalHeader from "../../../components/InternalHeader";
import Breadcrumb from "../../../components/Breadcrumb";

import ProgressAgendamento from "../../../components/ProgressAgendamento";
import DoctorRequesting from "../../../images/DoctorRequestingIcon.svg";
import OkIcon from "../../../images/okIcon.svg";
import NoIcon from "../../../images/noIcon.svg";
import IconRight from "../../../images/iconRight.svg";
import IconLeft from "../../../images/iconLeft.svg";

import {
  Flex,
  Box,
  Img,
  Text,
  Button,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Checkbox,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";

import TextInput from "../../../components/TextInput";


const schema = yup.object().shape({
  CRM: yup
    .string("CRM obrigatório")
    .max(999999)
    .required("O campo CRM obrigatório"),
  uf: yup.string(),
});

function Questionnaire() {
  const [hasExam, sethasExam] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const [openDateEnd, SetOpenDateEnd] = useState(false);
  const [openDateStart, SetOpenDateStart] = useState(true);
  const myCurrentDate = new Date();
  const myPastDate = new Date(myCurrentDate);
  const [startDate, setStartDate] = useState(myPastDate);
  registerLocale("ptBR", ptBR);

  const AnticoagulantesApi = [
    { nome: "Antigoagulante X" },
    { nome: "Antigoagulante X" },
    { nome: "Antigoagulante X" },
    { nome: "Antigoagulante X" },
    { nome: "Antigoagulante X" },
    { nome: "Antigoagulante X" },
    { nome: "Antigoagulante X" },
    { nome: "Antigoagulante X" },
    { nome: "Antigoagulante X" },
    { nome: "Antigoagulante X" },
  ];

  const {
    
    
    formState: { errors },
    
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  function Datepicker(date, setDate) {
    if (setDate === "Start") {
      setStartDate(date);
      SetOpenDateStart(openDateStart === true ? false : true);
    }
    if (setDate === "End") {
      setEndDate(date);
      SetOpenDateEnd(openDateEnd === true ? false : true);
    }
  }

  useEffect(() => {}, [hasExam]);
  return (
    <Flex backgroundColor="#F7F8FC" minH="100vh" minW="100vw">
      <Flex width="165px" id="sidebar" className="sidebar">
        <SideBar />
      </Flex>

      <Box
        className="page"
        padding={{ base: "10px", md: "24px 50px" }}
        flexDirection={{ base: "column", md: "row" }}
        w={{
          base: `calc(100% - 165px)`,
          md: `calc(100% - 300px))`,
        }}
      >
        <InternalHeader />
        <Breadcrumb
          page="Agendamento"
          pageTitle="Agendamento de exame líquor"
        />
        <Flex
          mb="15px"
          p="60px 105px"
          border="1px solid #CED4DA"
          borderRadius="10px"
          flexDirection="column"
          minW="1089px"
          backgroundColor="white"
        >
          <ProgressAgendamento />
          <Flex gap="24px" alignItems="center" mt="42px">
            <Img src={DoctorRequesting} w="30px" h="40px" />
            <Text fontWeight="600" fontSize="24px">
              Questionários
            </Text>
            <Text fontWeight="400" color="#6C757D">
              Responda todas as perguntas abaixo para processeguir.
            </Text>
          </Flex>
          <Divider mt="32px" />
          <Flex mt="38px" flexDirection="column" gap="8px">
            <Text as="strong" color="#231F20">
              O paciente faz uso de anticoagulante?
            </Text>
            <Flex gap="20px">
              <Button
                color="gray"
                w="100px"
                border="1px solid gray"
                backgroundColor="white"
                gap="10px"
                onClick={() => sethasExam(false)}
                _selected={{ backgroundColor: "blue" }}
              >
                Não
                <Img src={NoIcon} />
              </Button>

              <Button
                color="white"
                w="100px"
                border="1px solid #CED4DA"
                backgroundColor="#198754"
                gap="10px"
                onClick={() => sethasExam(true)}
              >
                Sim
                <Img src={OkIcon} />
              </Button>
            </Flex>
          </Flex>
          {hasExam === false && (
            <Flex w="100%" h="100%" justifyContent="space-between" mt="125px">
              <Button backgroundColor="#343A40" color="white" gap="8px">
                <Img src={IconLeft} />
                Voltar
              </Button>
              <Button backgroundColor="#F3953F" color="white" gap="8px">
                Continuar
                <Img src={IconRight} />
              </Button>
            </Flex>
          )}
        </Flex>

        {hasExam === true && (
          <Flex w="100%" gap="14px">
            <Flex
              mb="15px"
              border="1px solid #CED4DA"
              borderRadius="10px"
              flexDirection="column"
              w="100%"
              minW="730px"
              backgroundColor="white"
              p="40px 36px 71px 91px"
            >
              <Text as="strong" w="100%">
                Escolha quais anticoagulantes você faz uso:
              </Text>
              <Divider m="21px 0" backgroundColor="1px solid orange" />

              <TableContainer border="1px solid #D7D7D7" borderRadius="10px">
                <Table variant="simple" borderRadius="50px">
                  <Thead>
                    <Tr>
                      <Th borderRight="1px solid #D7D7D7"></Th>
                      <Th>
                        <Text as="strong">Nome do Antigoagulante</Text>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {AnticoagulantesApi?.map((item) => {
                      return (
                        <Tr>
                          <Td borderRight="1px solid #D7D7D7" w="39px">
                            <Checkbox />
                          </Td>
                          <Td>{item.nome}</Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
              <Flex mt="13px" justifyContent="flex-end">
                <Pagination />
              </Flex>

              <Divider m="21px 0" backgroundColor="1px solid orange" />
              <Flex flexDirection="column" gap="34px">
                <TextInput labelDescription="Qual a dose diária de anticoagulante?" />
                <TextInput labelDescription="Qual a frequência de uso do paciente?" />
                <Flex
                  w="100%"
                  p="5px 12px"
                  border="1px solid #CED4DA"
                  color="#8F8F8F"
                  backgroundColor="white"
                  borderRadius="4px"
                  alignContent="center"
                  alignItems="center"
                  gap="10px"
                  mb="10px"
                >
                  <DatePicker
                    open={openDateStart}
                    locale="ptBR"
                    height="100%"
                    dateFormat="dd/MM/yyyy "
                    selected={startDate}
                    onChange={(date) => Datepicker(date, "Start")}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={endDate}
                  />
                  <CalendarIcon
                    color="orange"
                    cursor="pointer"
                    onClick={() =>
                      SetOpenDateStart(openDateStart === true ? false : true)
                    }
                  />
                </Flex>

                <Flex w="100%" h="100%" justifyContent="space-between">
                  <Button backgroundColor="#343A40" color="white" gap="8px">
                    <Img src={IconLeft} />
                    Voltar
                  </Button>
                  <Button backgroundColor="#F3953F" color="white" gap="8px">
                    Continuar
                    <Img src={IconRight} />
                  </Button>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              mb="15px"
              border="1px solid #CED4DA"
              borderRadius="10px"
              flexDirection="column"
              minW="343px"
              maxH="553px"
              backgroundColor="white"
              p="22px"
            >
              <Flex
                borderRadius="26px"
                backgroundColor="#F7F8FC"
                w="299px "
                minH="471px"
                justifyContent="center"
              >
                <Text
                  mt="33px"
                  w="234px"
                  fontWeight="600"
                  color="#00000050"
                  textAlign="center"
                >
                  Você não cadastrou nenhum anticoagulante.
                </Text>
              </Flex>
              <Box minH="59px">
                <Button
                  m="14px 15px 10px 180px"
                  h="34px"
                  backgroundColor="#F3953F"
                  color="white"
                  gap="8px"
                >
                  Continuar
                  <Img src={IconRight} />
                </Button>
              </Box>
            </Flex>
          </Flex>
        )}
      </Box>
    </Flex>
  );
}

export default Questionnaire;
