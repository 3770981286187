import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Button,
  Box,
  Flex,
  Input,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  useDisclosure,

} from "@chakra-ui/react";

import { useAuth, useExams } from "../../hooks";
import { formatDate } from "../../helpers";
import { Pagination } from "../../components/Pagination";
// import { useEffect } from "react";

// const [widthScreen, setWidthScreen] = useState(0);


const breakpoints = {
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

const GridAppointment = ({
  showAppointment,
  pageCountAppointment,
  setCurrentPageAppointment,
  currentPageAppointment,
  onChildClick

}) => {
  const { user } = useAuth();

  const {
    changePatientRelease
  } = useExams();

  const [idAppointment, setIdAppointment] = useState();
  const [tapType, setTapType] = useState();
  const [patName, setPatientName] = useState();

  const [hrManipulation, setHrManipulation] = useState();
  const [hrEstability, setHrEstability] = useState();
  const [qtPlaquet, setQtPlaquet] = useState();

  const handleHrManipulation = (event) => setHrManipulation(event.target.value);
  const handleHrEstability = (event) => setHrEstability(event.target.value);
  const handleQtPlaquet = (event) => setQtPlaquet(event.target.value);

  const handleButton = (key, tapType, patientName, status, type) => {
    if (type === "Centro Cirurgico") {
      return
    }

    if (!status || status === 1) {
      onOpen();
      setTapType(tapType);
      setPatientName(patientName);
      setIdAppointment(key);
    }
  }
  const { isOpen, onOpen, onClose } = useDisclosure();


  async function postPatientRelease() {
    try {
      const data = {
        Hash: 'DD1793AAC837C34BE053E600A8C0C7AE',
        Type: user.role_id,
        Id: idAppointment,
        User: user.name, //'gabriel.mariano@senneliquor.com.br',
        MedicationTime: hrManipulation,
        EstabilityTime: hrEstability,
        PlaquetNumber: qtPlaquet,
      };

      const response = await changePatientRelease(data);

      if (response.response === "Sucesso") {

        toast.success("Paciente liberado com sucesso!");
        onChildClick();

        setTapType(null);
        setPatientName(null);
        setIdAppointment(null);
        onClose();

      }
    } catch (error) {
      toast.error("Repita o processo novamente!");
    }
  }

  function goTo() {
    const token = localStorage.getItem("Auth_senne")
    const hospitalUuid = localStorage.getItem("hospital")
    const userId = JSON.parse(localStorage.getItem("User_senne")).id
    window.location.replace(`https://agenda.senneliquor.com.br/login/sso?token=${token}&hospitalUuid=${hospitalUuid}&userId=${userId}`);

    // window.location.replace(`http://localhost:3000/login/sso?token=${token}&hospitalUuid=${hospitalUuid}&userId=${userId}`);
  }

  return (
    <>
      <Box

        m="18px 0"
        p="10px 20px"
        border="1px solid #CED4DA"
        borderRadius="10px"
        backgroundColor="var(--cinza-bg)"
      >

        <Flex direction="row" justifyContent="space-between" >

          <Flex >
            <Text
              color="#F3953F"
              fontSize={{ base: "16px", md: "26px" }} as="strong"
              fontWeight={800}
              alignSelf="end"
            >
              Agendamentos
            </Text>
          </Flex>
          <Flex flexDirection="column" h="40px" w="100%" minW="50px" maxW="200px" marginBottom="2">

            <Button

              border="1px solid #CED4DA"
              w="100%"
              h="100%"
              backgroundColor="var(--orange-senne)"
              _hover={{ opacity: 0.7 }}
              p="10px 10px"
              color="white"
              // eslint-disable-next-line no-undef
              onClick={goTo}

            >
              Novo Agendamento
            </Button>
          </Flex>

        </Flex>

        <Flex
          fontSize="13px"
          // overflowX="scroll"
          // scrollBehavior="none"
          flexDirection="column"
        // border="1px solid #CED4DA"
        >
          <TableContainer
            border="1px solid #CED4DA"
            borderRadius="6px"
            w="100%"
          // h="494px"
          // maxH="516px"

          // maxW={`calc(100% - ${widthScreen})`}


          // overflowY="scroll"
          >
            {showAppointment?.length > 0 ? (
              <Table
                variant="simple"
                border="var(--cinza-card) 1px solid"
                borderRadius="50px"
              >
                <Thead>
                  <Tr borderRadius="8px">
                    {/* <Th borderRadius="8px" maxW="50px"></Th> */}
                    <Th maxW="80px">
                      Data Agendamento
                    </Th>
                    {/* <Th maxW="160px">Nome do Paciente</Th> */}
                    <Th maxW="800px">Nome Paciente</Th>
                    {/* <Th maxW="180px">
                    Médico <br /> Solicitante
                  </Th> */}
                    <Th maxW="100px">Local</Th>
                    <Th maxW="120px">Convênio</Th>
                    <Th maxW="100px">
                      Número do<br /> Agendamento
                    </Th>
                    <Th maxW="120px">Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {showAppointment?.map((appoint, index) => (
                    <Tr
                      onClick={() => handleButton(appoint.id, appoint.taptype, appoint.patient, appoint.ie_status_atual, appoint.type)}

                      cursor="pointer"
                      key={appoint.id}
                      _hover={{ backgroundColor: "#F3953F15" }}
                      backgroundColor={
                        appoint.status === 3 ? "#FF000015" : "white"
                      }
                    >
                      {/* <Td maxW="50px" key="index">
                      <Checkbox />
                    </Td> */}
                      <Td
                        maxW="100px"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        <Flex justifyContent="space-between">
                          <Tooltip
                            hasArrow
                            label={formatDate(appoint?.schedule)}
                            bg="gray.300"
                            color="black"
                          >
                            {/* <Text as="strong">{appoint?.schedule }{ appoint.chemo ? " - " + appoint.chemo : ""  }</Text> */}
                            <Text as="strong">{appoint?.schedule}</Text>
                          </Tooltip>

                        </Flex>
                      </Td>
                      <Td maxW="120px" align="left">
                        <Tooltip
                          hasArrow
                          label={appoint?.patient}
                          bg="gray.300"
                          color="black"
                        >
                          <Text as="strong">{appoint?.patient}</Text>
                        </Tooltip>


                      </Td>

                      <Td maxW="80px">{appoint?.type}</Td>

                      <Td maxW="120px">{appoint?.insurance}</Td>

                      <Td maxW="70px">{appoint?.id}</Td>
                      <Td>
                        <Flex
                          w="90px"
                        >
                          <Text>{appoint?.status}</Text>
                        </Flex>
                      </Td>

                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <Text p="80px 20px">
                Não existem agendamentos para esse período. <br />

              </Text>
            )}
          </TableContainer>
        </Flex>
        {/* Button imprimir laudos */}
        <Flex p="20px 20px 0 0" justifyContent="space-between">
          <Pagination
            pageCount={pageCountAppointment}
            changePage={setCurrentPageAppointment}
            pageSelected={currentPageAppointment}
          />
        </Flex>

      </Box>
      <>
        <Modal onClose={onClose} isOpen={isOpen} size="xl">
          <ModalOverlay>
            <ModalContent>
              <ModalHeader>
                <Text>{tapType}</Text>

              </ModalHeader>

              <ModalBody>
                <Text>ID.: {idAppointment}</Text>
                <Text>Nome: {patName}</Text>
                <br />
                <Text as='b'>Para confirmar a liberação do paciente, preencha as informações abaixo:</Text>

                <br />
                <br />

                <Text>Qual hora a medicação foi manipulada?</Text>

                <Input
                  placeholder="Qual hora a medicação foi manipulada?"
                  size="md"
                  w="50"
                  type="time"
                  value={hrManipulation}
                  onChange={handleHrManipulation}
                />


                <Text>Qual a estabilidade da manipulação?</Text>

                <Input
                  placeholder="Qual a estabilidade da manipulação?"
                  size="md"
                  w="50"
                  type="time"
                  value={hrEstability}
                  onChange={handleHrEstability}
                />

                <Text>Quantidade de plaquetas?</Text>

                <Input
                  placeholder="Plaquetas"
                  value={qtPlaquet}
                  onChange={handleQtPlaquet}
                />
              </ModalBody>
              <ModalFooter>

                <Button
                  color="#fff"
                  backgroundColor="#f3953f"
                  onClick={() => postPatientRelease()}>Enviar</Button>
                <ModalCloseButton />

              </ModalFooter>

            </ModalContent>


          </ModalOverlay>

        </Modal>
      </>
    </>

  );
};

export default GridAppointment;
