import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks";

import { routes, authenticatedRoutes } from "./constants";

export const RequireAuth = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default function Router() {
  // const { isLoggedIn } = useAuth();
  const location = useLocation();
  useEffect(() => {
    if (routes.find((item) => item.path === location.pathname)) {
      localStorage.removeItem("Auth_senne");
      localStorage.removeItem("User_senne");
    }
  }, []);

  return (
    <Routes>
      {routes.map(({ path, element }, index) => (
        <Route key={index} path={path} element={element} />
      ))}
      {authenticatedRoutes.map(({ path, element }, index) => (
        <Route
          key={index}
          path={path}
          element={<RequireAuth>{element}</RequireAuth>}
        />
      ))}
    </Routes>
  );
}
