import styled from "styled-components";

const Content = styled.div`
  background-color: #fff;
  padding: 29px 20px;
  border-radius: 19px;
  width: 100%;
  flex: 1;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

export { Content };
