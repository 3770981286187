import React from "react";
import { PaginationStyles } from "./styles";
import ReactPaginate from "react-paginate";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

export const Pagination = ({ pageCount, changePage, pageSelected }) => {
  function handlePageChange(event) {
    changePage(event.selected);
    
  }

  return (
    <>
      <PaginationStyles />
      <ReactPaginate
        className="paginator"
        nextLabel={<ChevronRightIcon />}
        previousLabel={<ChevronLeftIcon />}
        pageRangeDisplayed={3}
        pageCount={pageCount || 1}
        onPageChange={handlePageChange}
        forcePage={pageSelected || 0}

      />
    </>
  );
};