import { useState } from "react";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Flex, Text, Img, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import styled from "styled-components";
import * as yup from "yup";

import { GoBackButton, RegisterButton } from "../../../components/Buttons";
import TemplateRegister from "../../../components/TemplateRegister";
import TextInput from "../../../components/TextInput";

import Labor from "../../../images/LabCar.svg";

const schema = yup.object().shape({
  nameempresa: yup.string().required("O campo nome da empresa é obrigatório"),
  razaosocial: yup.string().required("O campo razao social é obrigatório"),
  cnpj: yup.string().required("O campo CNPJ é obrigatório"),
});
export default function LaboratoryStepTwo({ props, setSteps, setProps }) {
  const [setor, setSetor] = useState();

  async function submit(values) {
    const values_API = {
      ...values,
      ...props,
    };
    setSteps(2);
    setProps(values_API);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  return (
    <TemplateRegister>
      <Content>
        <Flex
          flexDirection="column"
          backgroundColor="white"
          p="26px 46px"
          borderRadius="8px"
          w="100%"
        >
          <form onSubmit={handleSubmit(submit)}>
            <Flex
              flexDirection="column"
              alignContent="center"
              alignItems="center"
              mb="25px"
              gap="10px"
            >
              <Flex
                w="100%"
                justifyContent="center"
                gap="1.5em"
                alignContent="center"
                alignItems="center"
              >
                <Img w="42px" src={Labor} alt="" />
                <Text
                  w="150px"
                  as="strong"
                  color="var(--orange-senne-secundary)"
                >
                  Demais
                  <br /> Parceiros
                </Text>
              </Flex>
              <h1 fontSize="40px">Faça seu Cadastro</h1>
            </Flex>

            <Flex gap="26px" justifyContent="center" alignItems="center">
              <TextInput
                isError={errors.nameempresa}
                register={{ ...register("nameempresa") }}
                labelDescription="Nome Fantasia"
                InputPlaceholder="Nome fantasia"
                name="nameempresa"
                id="nameempresa"
              />
            </Flex>
            <Flex flexDirection="column">
              <TextInput
                isError={errors.razaosocial}
                register={{ ...register("razaosocial") }}
                labelDescription="Razão Social"
                InputPlaceholder="Razao Social"
                name="razaosocial"
                id="razaosocial"
              />
              <Flex gap="26px" justifyContent="center" alignItems="center">
                <TextInput
                  isError={errors.cnpj}
                  register={{ ...register("cnpj") }}
                  labelDescription="Digite seu CNPJ"
                  InputPlaceholder="00.000.000/0000-00"
                  maskInput="99.999.999/9999-99"
                  name="cnpj"
                  id="cnpj"
                />
              </Flex>
              <Flex flexDirection="column">
                <Text as="strong">Classificação</Text>

                <RadioGroup onChange={setSetor} value={setor} colorScheme="red">
                  <Stack direction="row" gap="20px">
                    <Radio value="hospital" {...register("classification")}>
                      Hospital
                    </Radio>
                    <Radio value="clinica" {...register("classification")}>
                      Clinica
                    </Radio>
                    <Radio value="labApoio" {...register("classification")}>
                      Lab. Apoio
                    </Radio>
                    <Radio value="outros" {...register("classification")}>
                      outros
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Flex>
            </Flex>

            <Flex mt="50px" justifyContent="space-between" alignItems="center">
              <Link to="/">
                <GoBackButton />
              </Link>

              <RegisterButton type="submit">Continuar</RegisterButton>
            </Flex>
          </form>
        </Flex>
      </Content>
    </TemplateRegister>
  );
}
const Content = styled.div`
  padding: 105px 0;
  color: var(--gray-dark);
  h1 {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    line-height: 36px;
    font-weight: bold;
    font-weight: 700;
  }
  h2 {
    font-size: 1rem;
    font-weight: 600;
  }
  p {
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0;
  }
  > div {
  }
  a {
    text-decoration: none;
  }
`;
