import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import TemplateLogin from "../../components/TemplateLogin";

import { LogginButton, GoBackButton } from "../../components/Buttons";
import { Flex } from "@chakra-ui/react";
import Person from "../../images/person.svg";
import SenneIcon from "../../images/logo_w.svg";
import PersonDoctor from "../../images/personDoctor.svg";


import Lab from "../../images/lab.svg";
//import Medico from "../../images/person-fill.svg";
const chaves = Object.keys(localStorage);
const chavesParaManter = ['Auth_senne', 'User_senne'];

chaves.forEach(chave => {
  if (!chavesParaManter.includes(chave)) {
    localStorage.removeItem(chave)
  }
});

function Login() {
  // console.log('ENV', process.env.REACT_APP_API_URL);
  return (
    <>
      <TemplateLogin>
        <Content>
          <img src="../../images/CentraExames.svg" alt="" />
          <p>
            Centro de referência em coleta e análise de líquor com experiência e
            inovação para trazer o melhor resultado.
          </p>

          <Flex
            flexDirection="column"
            backgroundColor="white"
            borderRadius="19px"
            width="100%"
            p="30px 60px"
          >
            <Link to="/login/paciente">
              <LogginButton>
                <img src={Person} alt="" />
                Paciente ou Responsável
              </LogginButton>
            </Link>

            {/* <Link to="https://sistemas.senneliquor.com.br/LaboratorioWeb/indexLaboratorio.jsp">
              <LogginButton>
                <img src={Person} alt="" />
                Paciente ou Responsável
              </LogginButton>
            </Link>  */}

            <Link to="/login/hospital">
              <LogginButton>
                <img src={Lab} alt="" />
                Corpo Clínico e Colab. dos Hospitais
              </LogginButton>
            </Link>
            <Link to="/login/medico">
              <LogginButton>
                <img src={PersonDoctor} alt="" />
                Médico Prescritores
              </LogginButton>
            </Link>
            {/* <Link to="https://sistemas.senneliquor.com.br/LaboratorioWeb/indexLaboratorio.jsp">
              <LogginButton>
                <img src={PersonDoctor} alt="" />
                Médico
              </LogginButton>
            </Link> */}

            <Link to="/login/administrador">
              <LogginButton>
                <img src={SenneIcon} alt="" />
                Operação Senne Liquor
              </LogginButton>
            </Link>
            <Flex w="100%" mt="10px" justifyContent="center">
              <Link to="/">
                <GoBackButton />
              </Link>
            </Flex>
          </Flex>
        </Content>
      </TemplateLogin>
    </>
  );
}

const Content = styled.div`
  padding: 2em 7.5em;
  /* max-height: calc(100vh - 141px); */
  color: #fff;

  p {
    line-height: 23px;
    font-size: 1rem;
    margin: 16px 0;
  }
  // > div {
  //   margin: 24px 0 0;
  // }
  a {
    text-decoration: none;
  }

  @media (max-width: 1200px) {
    padding: 15px;
    h1 {
      font-size: 1.2rem;
      margin: 0 0 14px;
    }
    p {
      font-size: 0.8rem;
    }
  }
`;

export default Login;
