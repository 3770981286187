import { ExamsContext } from "../contexts/Exams";
import { useContext } from "react";

export function useExams() {
  const context = useContext(ExamsContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}
