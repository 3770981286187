import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import TemplateRegister from "../../components/TemplateRegister";
import styled from "styled-components";
// import TextInput from "../../components/TextInput";
import TextInput from "../../components/TextInput";
import Card from "../../components/Card";
import { Flex, Box, Text, List, ListItem, ListIcon } from "@chakra-ui/react";
import { SmallCloseIcon, CheckIcon } from "@chakra-ui/icons";
import { RegisterButton } from "../../components/Buttons";
import { useAuth } from "../../hooks";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  // email: yup.string().email().required("Digite seu Email"),
  password: yup
    .string()
    .required("Digite a Senha")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/,
      " ",
    ),
  password_confirmation: yup
    .string()
    .required(" ")
    .oneOf([yup.ref("password"), null], " As senhas devem ser idênticas"),
});

function ResetPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({});
  const { resetPassword } = useAuth();

  const [values, setValues] = useState({});

  const [hasNumber, setHasNumber] = useState();
  const [hasSpecialCharacters, setHasSpecialCharacters] = useState();
  const [hasUppercase, setHasUppercase] = useState();
  const [hasSixCharacters, setHasSixCharacters] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  function validation(event) {
    const password = event.target.value;
    password.match(/^(?=.*[a-z])/) && password.match(/^(?=.*[A-Z])/)
      ? setHasUppercase(true)
      : setHasUppercase(false);

    password.length > 5
      ? setHasSixCharacters(true)
      : setHasSixCharacters(false);

    password.match(/^(?=.*[0-9])/) ? setHasNumber(true) : setHasNumber(false);
    password.match(/^(?=.*["@,#,$,%,&,!,*"])/)
      ? setHasSpecialCharacters(true)
      : setHasSpecialCharacters(false);
  }

  function handleChange(event) {
    validation(event);
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  }

  const submit = async (values) => {
    const token = searchParams.get("token");
    const key = searchParams.get("key");
    const status = parseInt(searchParams.get("status"));

    const data = { ...values, token, key, status };
    try {
      const response = await resetPassword(data);

      if (response.message === "Senha alterada com sucesso") {
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <TemplateRegister>
      <Content onSubmit={handleSubmit(submit)}>
        <Card>
          <Flex
            flexDirection="column"
            alignContent="center"
            alignItems="center"
            mb="25px"
          >
            <img src="images/key-fill.svg" alt="" />
            <h1>Criação de senha</h1>
          </Flex>
          <Box>
            <Box mt="32px">
              <TextInput
                isError={errors.password}
                labelDescription="Digite senha"
                InputPlaceholder="Digite senha"
                type="password"
                onChange={handleChange}
                name="password"
                register={{ ...register("password") }}
                password
              ></TextInput>
            </Box>

            <List paddingLeft="0px !important " mb="23px" spacing={1}>
              <ListItem>
                <Text as="strong">
                  Para que voce tenha uma senha segura, utilize:
                </Text>
              </ListItem>

              <ListItem>
                {hasUppercase === true ? (
                  <ListIcon as={CheckIcon} color="blue.500" />
                ) : (
                  <ListIcon as={SmallCloseIcon} color="black.500" />
                )}
                <Text as="strong">
                  Letras maiúsculas e minúsculas (Ex: H, g, T, r):
                </Text>
              </ListItem>
              <ListItem>
                {hasNumber === true ? (
                  <ListIcon as={CheckIcon} color="blue.500" />
                ) : (
                  <ListIcon as={SmallCloseIcon} color="black.500" />
                )}
                <Text as="strong">Caracteres numéricos (Ex: 0, 2);</Text>
              </ListItem>
              <ListItem>
                {hasSpecialCharacters === true ? (
                  <ListIcon as={CheckIcon} color="blue.500" />
                ) : (
                  <ListIcon as={SmallCloseIcon} color="black.500" />
                )}
                <Text as="strong">Mínimo de 8 caracteres (Ex: @, !, #);</Text>
              </ListItem>
            </List>

            <TextInput
              isError={errors.password_confirmation}
              labelDescription="Confirme sua senha"
              InputPlaceholder="Confirme sua senha"
              type="password"
              name="password_confirmation"
              register={{ ...register("password_confirmation") }}
              password
            />
            <Flex
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              padding="0 90px"
            >
              <RegisterButton type="submit">Continuar</RegisterButton>
            </Flex>
          </Box>
        </Card>
      </Content>
    </TemplateRegister>
  );
}

const Content = styled.form`
  width: 535px;
  padding: 100px 0;
  ul {
    padding-left: 20px;
  }

  h1 {
    width: 100%;
    text-align: center;
    font-size: 36px;
    line-height: 36px;
    font-weight: bold;
  }
  p {
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0;
  }
  a {
    text-decoration: none;
  }
`;

export default ResetPassword;
