import { useState, useEffect } from "react";
import { Button, Flex, List, ListItem, ListIcon, Text } from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { toast } from "react-toastify";

import TextSelect from "../../components/TextSelect";
import Loading from "../../components/Loading";

import { useUsers } from "../../hooks";

export default function AproveDoctor(props) {
  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState();
  const [newList, setNewList] = useState();
  const [dadosTasy, setDadosTasy] = useState();
  const [position, setPosition] = useState();
  const { getUserDoctor, approveDoctor, approveUser } = useUsers();

  function getNumberCRM(string) {
    const newNumber = string.indexOf("/");
    return dados?.crm.slice(0, newNumber);
  }

  async function getListDoctor() {
    
    try {

      const uf = dados?.crm.slice(-2);
      const response = await getUserDoctor(getNumberCRM(dados?.crm), uf);

      console.log(response?.doctors.rows)
      if (response?.doctors.rows) {
        setDadosTasy(response?.doctors.rows);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function AproveDoctorSenne(hashMedico, codmedico) {
    try {
      if (position === undefined || position === "" || !position) {
        toast.error("É necessário selecionar um médico da lista");
        return;
      }

      setLoading(true);

      props.setOpenAprove(false);

      const params = {
        hash_medico: hashMedico,
        codmedico: codmedico,
        role_id: 4,
      };


      await approveUser(dados?.id, params);
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  }

  function listTasyData() {
    const list = [];
    if (dadosTasy) {
      dadosTasy.map((item, index) => {
        return list.push({
          id: index,
          name: item?.doctorName,
        });
      });
      setNewList(list);
    }
  }
  useEffect(() => {
    setDados(props);

  }, []);

  useEffect(() => {
    getListDoctor();
    listTasyData();
  }, [dados]);

  useEffect(() => {
    listTasyData();
  }, [dadosTasy]);
  return (
    <Flex
      position="absolute"
      left="0"
      top="0"
      w="100%"
      h="100%"
      display="flex"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      backgroundColor="#00000050"
    >
      {loading && <Loading />}
      {dados && (
        <Flex
          borderRadius="20px"
          w="400px"
          h="500px"
          backgroundColor="white"
          justifyContent="space-between"
          flexDirection="column"
          p="20px"
        >
          <Flex flexDirection="column" gap="10px">
            <TextSelect
              titleOption="Médicos"
              labelDescription="Escolha um Médico"
              options={newList}
              onChange={(e) => setPosition(e.target.value)}
            />

            <Flex flexDirection="column">
              <Text as="strong">Dados Cadastrados</Text>
              <List>
                <ListItem>
                  <Text as="strong">Nome: </Text>

                  {dados.name}
                </ListItem>

                <ListItem>
                  <Text as="strong">Crm: </Text>

                  {getNumberCRM(dados.crm)}
                </ListItem>
              </List>
            </Flex>
            <Flex flexDirection="column">
              <Text m="20px 0 0" as="strong">
                Dados Tasy
              </Text>
              {dadosTasy && (
                <>
                  {position ? (
                    <List>
                      <>
                        <ListItem>
                          {dadosTasy[position]?.doctorName.replaceAll(
                            " ",
                            "",
                          ) === dados?.name.replaceAll(" ", "") ? (
                            <ListIcon as={CheckCircleIcon} color="green.500" />
                          ) : (
                            <ListIcon as={WarningIcon} color="red.500" />
                          )}
                          <Text as="strong">Nome: </Text>
                          {dadosTasy[position]?.doctorName}
                        </ListItem>
                        <ListItem>
                          {dadosTasy[position]?.doctorCRM ===
                          getNumberCRM(dados?.crm) ? (
                            <ListIcon as={CheckCircleIcon} color="green.500" />
                          ) : (
                            <ListIcon as={WarningIcon} color="red.500" />
                          )}
                          <Text as="strong">Crm: </Text>

                          {dadosTasy[position]?.doctorCRM + "/" + dadosTasy[position]?.doctorUF}
                        </ListItem>
                      </>
                    </List>
                  ) : (
                    <Text m="10px 0">
                      Selecione um usuário ou cadastre no Tasy.
                    </Text>
                  )}
                </>
              )}
            </Flex>
          </Flex>

          <Flex w="100%" justifyContent="space-between">
            <Button
              backgroundColor="var(--green-senne)"
              onClick={() =>
                AproveDoctorSenne(
                  dadosTasy[position]?.hash,
                  dadosTasy[position]?.doctorId,
                )
              }
              color="white"
              _hover={{ opacity: 0.6 }}
            >
              Aprovar
            </Button>
            <Button
              onClick={() => props.setOpenAprove(false)}
              backgroundColor="red"
              color="white"
              _hover={{ opacity: 0.6 }}
            >
              Voltar
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
