import styled from "styled-components";
import HeaderLogo from "./HeaderLogo";
import BackgroundRegister from "../../src/images/reset-passwordBG.png";

export default function TemplateLogin({ children, colorClass }) {
  return (
    <>
      <HeaderLogo />
      <BlockPrimary>
        <div className={`content ${colorClass}`}>{children}</div>
      </BlockPrimary>
    </>
  );
}

const BlockPrimary = styled.div`
  /* border-top: 2px solid #ec6726; */

  margin: 0;
  padding: 0;
  min-height: calc(100vh - 103px);
  width: 100%;
  background: url(${BackgroundRegister});
  background-repeat: no-repeat;

  background-size: cover;
  /* background-color: #ec6726; */

  .content {
    display: flex;
    justify-content: center;
    align-content: center;

    &.green {
      background-color: #b9bd5c;
    }
    &.color-senne {
      background-color: #b9bd5c;
    }
  }
  @media screen and (max-width: 800px) {
    display: block;
    .image {
      max-height: 300px;
      overflow: hidden;
    }
  }
`;
