import { createGlobalStyle } from "styled-components";

export const PaginationStyles = createGlobalStyle`
  .paginator {
    display: flex;
    li {
      list-style: none;
      display: flex;
      a {
        color: var(--orange-senne);
        font-weight: bold;
        margin: 0;
        padding: 6px 14px;
        border-radius: 4px;
        height: 40px;
        width: auto;
        text-align: center;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
          background-color: var(--orange-hover);
        }

      }
      &.selected {
        a {
          background-color: var(--orange-senne);
          color: #FFF;
        }
      }
    }
  }
  @media(max-width:600px){

    .paginator{
      li{
        a{
          font-size:12px;
          padding:3px;
          heigth:15px;
          width:20px;
        }
      }
    }
  }
`;
