import { useState } from "react";

import LaboratoryStepOne from "./laboratoryStepOne";
import LaboratoryStepTwo from "./laboratoryStepTwo";
import LaboratoryStepThree from "./laboratoryStepThree";

export default function RegisterPartners() {
  const [steps, setSteps] = useState(0);
  const [props, setProps] = useState();

  if (steps === 0) {
    return (
      <LaboratoryStepOne
        props={props}
        setSteps={setSteps}
        setProps={setProps}
      />
    );
  }

  if (steps === 1) {
    return (
      <LaboratoryStepTwo
        props={props}
        setSteps={setSteps}
        setProps={setProps}
      />
    );
  }

  return <LaboratoryStepThree props={props} />;
}
