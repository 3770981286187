import React from "react";


import { 
    Flex, 
    Text, 

  } from "@chakra-ui/react";

// import SelectHospitalPopup from "../SelectHospitalPopup";

import Cliente from "../../images/clienteSignin.svg";

// import RefreshIcon from "../../assets/icons/RefreshIcon.tsx";

import LogoIcon from "../../assets/icons/LogoIcon.tsx";

const InternalHeader = ({
  isMobile,
  user,
  hospitalName,
 
  
}) => {  

  const list = [
    {
      role_id: 1,
      text: "Administrador Senne",
    },
    {
      role_id: 2,
      text: user?.hospitals?.[0]?.name,
    },
    {
      role_id: 3,
      text: "Paciente",
    },
    {
      role_id: 4,
      text: "Médico",
    },
    {
      role_id: 5,
      text: "Atendimento",
    },
  ];
   
  return (

    <Flex
      alignContent="center"
      alignItems="center"
      gap={ !isMobile && "70px"}
      w="100%"
      mr="10px"
      flexDirection={{ base: "column", md: "row" }}
    >
      <Flex>

      
      {/* <Modal onClose={onClose} isOpen={isOpen} size="xl" isCentered>
        <ModalOverlay>
          <ModalContent>
            <ModalHeader style={{textAlign: "center", paddingTop: 50 }}>
              <Text color={"#990507"} fontSize={18}>Selecione o hospital que deseja ter acesso</Text>
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody style={{textAlign: "center"}}>
              
              <Flex 
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <SelectHospitalPopup
                  labelDescription=""
                  name="hospital"
                  id="idHospital"
                  titleOption="Escolha o hospital"
                  onChange={(e) => 
                    { 
                      handleOnChangeHospital(e.target.value);
                 
                    }
                  }
                  options={hospitalList}
                />


              </Flex>

            </ModalBody>
            <ModalFooter>
              <Button
                border="1px solid #CED4DA"
                backgroundColor="var(--orange-senne)"
                _hover={{ opacity: 0.7 }}
                p="10px 10px"
                color="white"
                onClick={onClose}
              >
                Fechar
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>

      </Modal> */}

        {

          isMobile && (
            <Flex paddingRight="2em">
              <LogoIcon />
            </Flex>
        
          )
        }

        <img src={Cliente} alt="logo" />
        <Flex flexDirection="column" ml="16px" w="100%">
        {list?.map((item, index) => {
            if (user?.role_id === item?.role_id) {
              return (
                
                <Text key={index+item} fontSize="13px" as="strong">
                  {hospitalName ? hospitalName : item.text}
                </Text>
                
              );
            }
          })}
          <Text as="strong" fontSize="13px" w="100%">
            {user?.name}
          </Text>
        </Flex>
      </Flex>
      <Flex>

        
      </Flex>
    </Flex>
  );
};

export default InternalHeader;
