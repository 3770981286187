import React from "react";

import SideBar from "../../../components/SideBar";

import InternalHeader from "../../../components/InternalHeader";
import Breadcrumb from "../../../components/Breadcrumb";

import ProgressAgendamento from "../../../components/ProgressAgendamento";
import PersonLines from "../../../images/PersonLines.svg";
import PersonGray from "../../../images/PersonGray.svg";
import PeopleGray from "../../../images/PeopleGray.svg";
import IconRight from "../../../images/iconRight.svg";
import IconLeft from "../../../images/iconLeft.svg";

import { Flex, Box, Img, Text, Button, Divider } from "@chakra-ui/react";
function WhoIsItFor() {
  return (
    <Flex backgroundColor="#F7F8FC" minH="100vh" minW="100vw">
      <Flex width="165px" id="sidebar" className="sidebar">
        <SideBar />
      </Flex>

      <Box
        className="page"
        padding={{ base: "10px", md: "24px 50px" }}
        flexDirection={{ base: "column", md: "row" }}
        w={{
          base: `calc(100% - 165px)`,
          md: `calc(100% - 300px))`,
        }}
      >
        <InternalHeader />
        <Breadcrumb
          page="Agendamento"
          pageTitle="Agendamento de exame líquor"
        />
        <Flex
          mb="15px"
          p="60px 105px"
          border="1px solid #CED4DA"
          borderRadius="10px"
          flexDirection="column"
          minW="1089"
        >
          <ProgressAgendamento />
          <Flex gap="24px" alignItems="center" mt="42px">
            <Img src={PersonLines} w="24px" h="24px" />
            <Text fontWeight="600" fontSize="24px">
              Dados do Paciente
            </Text>
          </Flex>
          <Divider mt="32px" />
          <Text as="strong" mt="27px">
            Este agendamento, é para você ou para outra pessoa?
          </Text>
          <Flex gap="27.2" mt="19px">
            <Button
              w="275px"
              h="64px"
              justifyContent="space-between"
              border="1px solid #343A40"
            >
              Para mim
              <Img src={PersonGray} w="24px" h="24px" />
            </Button>
            <Button
              w="275px"
              h="64px"
              justifyContent="space-between"
              border="1px solid #343A40"
            >
              Para outra pessoa
              <Img src={PeopleGray} w="24px" h="24px" />
            </Button>
          </Flex>
          <Flex w="100%" justifyContent="space-between" mt="190px">
            <Button backgroundColor="#343A40" color="white" gap="8px">
              <Img src={IconLeft} />
              Voltar
            </Button>
            <Button backgroundColor="#F3953F" color="white" gap="8px">
              Continuar
              <Img src={IconRight} />
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}

export default WhoIsItFor;
