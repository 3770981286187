import React from "react";
import { Link } from "react-router-dom";
import { Flex, Text, extendTheme } from "@chakra-ui/react";
import { useAuth } from "../../hooks";

const breakpoints = {
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

const inicio = [
  { role_id: 1, inicio: "/gerenciamento-senne" },
  { role_id: 2, inicio: "/resultados-exames" },
  { role_id: 3, inicio: "/resultados-exames" },
  { role_id: 4, inicio: "/resultados-exames" },
  { role_id: 5, inicio: "/resultados-exames" },
];

// const theme = extendTheme({ breakpoints });

const Breadcrumb = ({ page, pageTitle }) => {
  const { user } = useAuth();
  return (
    <Flex mt="60px" w="100%" flexDirection="column" gap="10px" color="#6E6E6E">
      <Text
        fontSize={{ base: "16px", md: "0.825rem" }}
        lineHeight="16.25px"
        fontWeight="400px"
        textShadow="#00000040 0.1em 0.1em 0.1em"
      >
        {inicio?.map((item) => {
          if (user?.role_id === item.role_id) {
            return (
              <>
                <Link to={item.inicio}>Página principal </Link> &gt; {page}
              </>
            );
          }
        })}
      </Text>
      <Text color="#F3953F" fontSize={{ base: "16px", md: "26px" }} as="strong">
        {pageTitle}
      </Text>
    </Flex>
  );
};

export default Breadcrumb;
