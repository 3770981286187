import { RegisterContext } from "../contexts/Register";
import { useContext } from "react";

export function useRegister() {
  const context = useContext(RegisterContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}
