import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Flex, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";

import { PrimaryButton, GoBackButton } from "../../../components/Buttons";
import TemplateLogin from "../../../components/TemplateLogin";
// import CheckboxInput from "../../../components/CheckboxInput";
import PasswordInput from "../../../components/TextInput";
import TextInput from "../../../components/TextInput";
import Loading from "../../../components/Loading";
import Card from "../../../components/Card";

import { isNumeric } from "../../../helpers/isNumeric";

import { useAuth } from "../../../hooks";

import Hospitalar from "../../../images/cliente.svg";

const schema = yup.object().shape({
  password: yup.string().required("Digite a senha"),
  email: yup.string().required("Digite o e-mail ou protocolo"),
});

// localStorage.clear();

export default function PacienteLogin() {
  const [loading, setLoading] = useState(false);

  const { signIn } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const protocoloNumber = (value) => {
    return /[a-zA-Z].*@/.test(value);
  };

  async function submit(values) {
    try {
      setLoading(true);

      if (isNumeric(values["email"])) {
        delete Object.assign(values, { protocol: values["email"] })["email"];
      }

      const response = await signIn(values, 3);

      if (response.message === "Não é possível logar com esse usuário nesse local. Favor escolha a opção correta de login.") {
        navigate("/login");
      }

      // if (response.htt !== 200 && response.data.user.length === 0) {
      //   console.error("Usuário não encontrado");
      //   return null;

      // }

      const params = {
        name: response.data.user,
        token: response.data.token,
        type: response.data.type,
        validateType: response.data.validateType,
      };

      // const type = response.data.validateType;

      const state = {
        cellphone: response.data.cellphone,
        expire_date: response.data.expire_date,
        sentId: response.data.sentId,
        email: response.data.email,
      }


      // return null; 

      switch (params.type) {
        case "protocol":
          navigate("/validar/paciente", { state: params });
          break;
        case "validate_cellphone":
          let { cellphone, expire_date, sentId } = response.data;

          navigate("/validar/numero", {
            state: {
              cellphone: cellphone,
              expire_date: expire_date,
              sentId
            },
          });
          break;
        case "user_validation":
          // console.log("navigate to", "/login/paciente/login-sms")
          navigate("/login/paciente/login-sms", { state });
          break;
        default:
          // navigate("/resultados-exames");
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setValue("email", value);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onBlur",
    // resolver: yupResolver(schema),
  });
  return (
    <TemplateLogin image="">
      {loading && <Loading />}
      <Content>
        <Card>
          <Flex
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            gap="37px"
            mb="71px"
          >
            <img src={Hospitalar} alt="" />
            <Text fontSize={{ base: "34px", md: "40px", lg: "56px" }} fontWeight="bold">
              Paciente
            </Text>

          </Flex>
          <form onSubmit={handleSubmit(submit)} autoComplete="nope">
            <TextInput
              isError={errors.email}
              labelDescription="Login com email ou protocolo"
              InputPlaceholder="Email ou protocolo"
              register={{ ...register("email") }}
              name="email"
              type="text"
              id="email"
              // onChange={(e) => setValue("email", e.target.value)}

              onChange={(e) => handleEmailChange(e)}
            />


            {
              !protocoloNumber(email) && (

                <PasswordInput
                  isError={errors.password}
                  labelDescription="Senha"
                  InputPlaceholder="Senha"
                  register={{ ...register("password") }}
                  name="password"
                  type="password"
                  id="password"
                  onChange={(e) => setValue("password", e.target.value)}
                  password

                />

              )


            }

            {
              protocoloNumber(email) && (

                <TextInput
                  isError={errors.cpf}
                  register={{ ...register("cpf") }}
                  labelDescription="Seu CPF"
                  InputPlaceholder="Digite seu CPF"
                  maskInput={"***.***.***-**"}
                  name="cpf"
                  id="cpf"
                  required
                />

              )


            }
            {/* <Flex alignItems="center" justifyContent="space-between">
              <CheckboxInput labelDescription="Lembrar Senha"></CheckboxInput> 

              <Link to="/nova-senha" target="_blank">
                <Text
                  color="var( --orange-senne-secundary)"
                  textDecoration="underline"
                >
                  Esqueci a Senha
                </Text>
              </Link>
            </Flex> */}
            <PrimaryButton autoFocus type="submit">
              Entrar
            </PrimaryButton>
          </form>
          <Flex width="100%" justifyContent="center">
            <Link to="/login">
              <GoBackButton />
            </Link>
          </Flex>
        </Card>
      </Content>
    </TemplateLogin>
  );
}
const Content = styled.div`
  padding: 72px 80px;

  h1 {
    font-size: 36px;
    line-height: 36px;
    font-weight: bold;
  }
  p {
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0;
  }
  > div {
    margin: 24px 0 0;
  }
  a {
    text-decoration: none;
  }
  @media (max-width: 414px) {
    padding: 15px;
    h1 {
      font-size: 1.5rem;
    }
    div {
      margin: 12px 0 20px 0;
    }
    input {
      margin-bottom: 10px;
    }
  }
`;
