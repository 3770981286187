import { Text, Flex, Box, Img } from "@chakra-ui/react";
import Dados from "../../images/dados.svg";

export default function ProgressAgendamento() {
  const Progress = [
    { image: Dados, text: "Dados do paciente" },
    { image: Dados, text: "Informações do Agendamento" },
    { image: Dados, text: "Informações para o exame" },
    { image: Dados, text: "Resumo" },
    { image: Dados, text: "Conclusão" },
  ];
  return (
    <Box
      flexDirection="column"
      w="100%"
      m="0px"
      p="0px"
      backgroundColor="white"
    >
      <Flex p="0 30px">
        <Flex w="100%" position="relative" backgroundColor="gray" top="28px">
          <Flex h="3px" w="25%" backgroundColor="orange"></Flex>
        </Flex>
      </Flex>

      <Flex
        w="100%"
        top="0px"
        position="relative"
        justifyContent="space-between"
        zIndex="999"
      >
        {Progress?.map((item) => {
          return (
            <Flex
              flexDirection="column"
              fontSize="0.825rem"
              w="105px"
              alignContent="center"
              justifyContent="space-between"
            >
              <Flex w="100%" justifyContent="center">
                <Img w="50px" src={item.image} />
              </Flex>
              <Text textAlign="center">{item.text}</Text>
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
}
