import { useNavigate } from "react-router-dom";
import { Flex, Text } from "@chakra-ui/react";

import { RegisterButton } from "../../../components/Buttons";
import TemplateRegister from "../../../components/TemplateRegister";

export default function CadastroSolicitadoUser() {
  const Navigate = useNavigate();
  return (
    <>
      <TemplateRegister>
        <Flex
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          h="calc(100vh - 103px)"
        >
          <Flex
            flexDirection="column"
            backgroundColor="white"
            p="32px 46px"
            borderRadius="8px"
          >
            <Flex
              textAlign="start"
              gap="16px"
              width="100%"
              flexDirection="column"
            >
              <Text
                fontSize="2.5rem"
                fontWeight="700"
                color="var(--orange-senne-secundary)"
                textAlign="center"
              >
                Solicitação de cadastro <br />
                realizada com sucesso
              </Text>
              <Text fontSize="0.8rem" w="540px">
                Sua <b>solicitação de cadastro </b> foi enviado ao administrador
                da instituição e está <b>pendente de ativação</b>. Você será
                notificado por e-mail quando o seu acesso for liberado.
              </Text>
              <RegisterButton
                background="var(--green-button)"
                onClick={() => Navigate("/login")}
              >
                Voltar para Login
              </RegisterButton>
            </Flex>
          </Flex>
        </Flex>
      </TemplateRegister>
    </>
  );
}
