import Home from "../pages";

import Login from "../pages/login";
import ForgotPassword from "../pages/Users/ForgotPassword";

import HospitalarLogin from "../pages/login/hospitalar/hospitalar";
import LaboratorioLogin from "../pages/login/laboratorio/laboratorio";
import MedicoLogin from "../pages/login/medico/medico";
import PacienteLogin from "../pages/login/paciente/paciente";
import SenneLogin from "../pages/login/Senne/index";
import AtendimentoLogin from "../pages/login/atendimento/index";
import Register from "../pages/Register/index";
import Exams from "../pages/Exams";
import DetailExam from "../pages/Exams/detalhes";
import Users from "../pages/Users";
import AddUser from "../pages/Users/AddUser";
import Profile from "../pages/Users/Profile";
import EditProfile from "../pages/Users/EditProfile";
import MasterManagement from "../pages/Master/MasterManagement";
import GroupsManagement from "../pages/Master/GroupsManagement";
import GroupManagement from "../pages/Master/GroupManagement";
import Dashboard from "../pages/Dashboard";


import StaticManagement from "../pages/Master/StatiticMagenement";
import GroupProfile from "../pages/Master/GroupProfile";
import HospitalProfile from "../pages/Master/HospitalProfile";
import HospitalMembers from "../pages/Master/MembersGroup";

import ResetPassword from "../pages/Users/ResetPassword";
import RegisterPatient from "../pages/Register/patient/patientRegister";
import RegisterDoctor from "../pages/Register/doctor/doctorStepOne";
import RegisterPartners from "../pages/Register/laboratory";
import RegisterHospital from "../pages/Register/hospital/hospitalStepOne";
import CadastroAprovado from "../pages/Register/patient/cadastroAprovado";
import CadastroSolicitadoLab from "../pages/Register/laboratory/cadastroSolicitadoLab";
import CadastroSolicitadoMed from "../pages/Register/doctor/cadastroSolicitadoMed";
import CadastroSolicitadoUserHospital from "../pages/Register/hospital/cadastroSolicitadoUserHospital";

import WhoISItFor from "../pages/agendamentos/ambulatorial/WhoIsItFor.jsx";
import InformationsForScheduling from "../pages/agendamentos/ambulatorial/InformationsForScheduling.jsx";
import PatientData from "../pages/agendamentos/ambulatorial/PatientData.jsx";
import NewPatientData from "../pages/agendamentos/ambulatorial/NewPatientData.jsx";
import ConfirmPatientData from "../pages/agendamentos/ambulatorial/ConfirmPatientData.jsx";
import CollectionPlace from "../pages/agendamentos/ambulatorial/CollectionPlace.jsx";
import ChooseUunit from "../pages/agendamentos/ambulatorial/ChooseUnit";
import DoctorRequesting from "../pages/agendamentos/ambulatorial/RequestingDoctor";
import Questionnaire from "../pages/agendamentos/ambulatorial/Questionnaire";

import ValidatePatient from "../pages/Register/patient/patientValidate";
import ValidateSMSPage from "../pages/ValidateSMS";
import ValidateLoginPatient from "../pages/login/paciente/loginSMS.jsx";
import Partners from "../pages/Partners";


export const authenticatedRoutes = [
  {
    breadcrumb: "Gerenciamento de Usuários",
    path: "/gerenciamento-senne",
    element: <MasterManagement />,
    auth: true,
  },
  {
    breadcrumb: "Estatisticas e ações",
    path: "/estatisticas",
    element: <StaticManagement />,
    auth: true,
  },
  {
    breadcrumb: "Gerenciamento de grupos",
    path: "/gerenciamento-senne/gerenciamento-grupos",
    element: <GroupsManagement />,
    auth: true,
  },
  {
    breadcrumb: "Gerenciamento de grupo",
    path: "/gerenciamento-senne/gerenciamento-grupos/:id_group",
    element: <GroupManagement />,
    auth: true,
  },
  {
    breadcrumb: "Editar de Grupo",
    path: "/gerenciamento-senne/gerenciamento-grupos/:id/editar",
    element: <GroupProfile />,
    auth: true,
  },
  {
    breadcrumb: "Gerenciamento de Hospitais",
    path: "/gerenciamento-senne/gerenciamento-grupos/:id_group/:id_hospital",
    element: <HospitalProfile />,
    auth: true,
  },
  {
    breadcrumb: "Gerenciamento de Usuarios",
    path: "/gerenciamento-senne/gerenciamento-grupos/membros/:id_Hospital",
    element: <HospitalMembers />,
    auth: true,
  },

  {
    path: "/agendamentos/ambulatorio",
    element: <WhoISItFor />,
    auth: true,
  },
  {
    path: "/agendamentos/ambulatorio/dados-do-paciente",
    element: <PatientData />,
    auth: true,
  },
  {
    path: "/agendamentos/ambulatorio/cadastro-novo-paciente",
    element: <NewPatientData />,
    auth: true,
  },
  {
    path: "/agendamentos/ambulatorio/confirmacao-paciente",
    element: <ConfirmPatientData />,
    auth: true,
  },
  {
    path: "/agendamentos/ambulatorio/coleta-local",
    element: <CollectionPlace />,
    auth: true,
  },
  {
    path: "/agendamentos/ambulatorio/informacoes-agendamento",
    element: <InformationsForScheduling />,
    auth: true,
  },
  {
    path: "/agendamentos/ambulatorio/informacoes-agendamento/unidade",
    element: <ChooseUunit />,
    auth: true,
  },
  {
    path: "/agendamentos/ambulatorio/informacoes-agendamento/medico-solicitante",
    element: <DoctorRequesting />,
    auth: true,
  },
  {
    path: "/agendamentos/ambulatorio/informacoes-agendamento/questionario",
    element: <Questionnaire />,
    auth: true,
  },

  // {
  //   path: "/gerenciamento-hospitais/membros/:id_group",
  //   element: <HospitalsMembers />,
  //   auth: true,
  // },
  // {
  //   path: "/gerenciamento-grupos/nova-equipe",
  //   element: <NewEquipe />,
  //   auth: true,
  // },

  {
    breadcrumb: "Relação de Atendimentos",
    path: "/resultados-exames",
    element: <Exams />,
    auth: true,
  },
  {
    breadcrumb: "Dashboard",
    path: "/dashboard",
    element: <Dashboard />,
    auth: true,
  },  
  {
    breadcrumb: "Resultados de exames",
    path: "/resultados-exames/:uuid",
    element: <Exams />,
    auth: true,
  },
  {
    breadcrumb: "Detalhes do atendimento",
    path: "/resultados-exames/:uuid/:id",
    element: <DetailExam />,
    auth: true,
  },
  {
    breadcrumb: "Gerenciamento de usuarios",
    path: "/users",
    element: <Users />,
    auth: true,
  },
  {
    breadcrumb: "Gerenciamento de parceiros",
    path: "/partners",
    element: <Partners />,
    auth: true,
  },
  {
    breadcrumb: "Adicionar membro",
    path: "/users/add/:id_group",
    element: <AddUser />,
    auth: true,
  },
  {
    breadcrumb: "Adicionar membro",
    path: "/users/add",
    element: <AddUser />,
    auth: true,
  },
  {
    breadcrumb: " Perfil",
    path: "/users/:id",
    element: <Profile />,
    auth: true,
  },
  {
    breadcrumb: "Editar perfil",
    path: "/users/:id/editar",
    element: <EditProfile />,
    auth: true,
  },
];

export const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/nova-senha",
    element: <ForgotPassword />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/login/paciente",
    element: <PacienteLogin />,
  },
  {
    path: "/login/administrador",
    element: <SenneLogin />,
  },
  {
    path: "/login/atendimento",
    element: <AtendimentoLogin />,
  },
  {
    path: "/login/hospital",
    element: <HospitalarLogin />,
  },
  {
    path: "/login/laboratorio",
    element: <LaboratorioLogin />,
  },
  {
    path: "/login/medico",
    element: <MedicoLogin />,
  },
  {
    path: "/cadastro",
    element: <Register />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/cadastro/paciente",
    element: <RegisterPatient />,
  },
  {
    path: "/validar/paciente",
    element: <ValidatePatient />,
  },
  {
    path: "/validar/numero",
    element: <ValidateSMSPage />,
  },
  {
    path: "/login/paciente/login-sms",
    element: <ValidateLoginPatient />,
  },  
  {
    path: "/cadastro/medico",
    element: <RegisterDoctor />,
  },

  {
    path: "/cadastro/parceiros",
    element: <RegisterPartners />,
  },
  {
    path: "/cadastro/hospital",
    element: <RegisterHospital />,
  },
  {
    path: "/cadastro-aprovado",
    element: <CadastroAprovado />,
  },
  {
    path: "/cadastro-solicitado-parceiros",
    element: <CadastroSolicitadoLab />,
  },
  {
    path: "/cadastro-solicitado-medico",
    element: <CadastroSolicitadoMed />,
  },
  {
    path: "/cadastro-solicitado",
    element: <CadastroSolicitadoUserHospital />,
  },
];

export const estados = [
  { id: "AC", name: "AC" },
  { id: "AL", name: "AL" },
  { id: "AP", name: "AP" },
  { id: "AM", name: "AM" },
  { id: "BA", name: "BA" },
  { id: "CE", name: "CE" },
  { id: "DF", name: "DF" },
  { id: "ES", name: "ES" },
  { id: "GO", name: "GO" },
  { id: "MA", name: "MA" },
  { id: "MT", name: "MT" },
  { id: "MS", name: "MS" },
  { id: "MG", name: "MG" },
  { id: "PA", name: "PA" },
  { id: "PB", name: "PB" },
  { id: "PR", name: "PR" },
  { id: "PE", name: "PE" },
  { id: "PI", name: "PI" },
  { id: "RJ", name: "RJ" },
  { id: "RN", name: "RN" },
  { id: "RS", name: "RS" },
  { id: "RO", name: "RO" },
  { id: "RR", name: "RR" },
  { id: "SC", name: "SC" },
  { id: "SP", name: "SP" },
  { id: "SE", name: "SE" },
  { id: "TO", name: "TO" },
];

export const conselhos = [ 
  { id: "COREN" , name: "COREN" },
  { id: "CRAS", name: "CRAS" },
  { id: "CRBIO", name: "CRBIO" },
  { id: "CRBM", name: "CRBM" },
  { id: "CREFITO", name: "CREFITO" },
  { id: "CRF", name: "CRF" },
  { id: "CRFA", name: "CRFA" },
  { id: "CRM", name: "CRM" },
  { id: "CRN", name: "CRN" },
  { id: "CRO", name: "CRO" },
  { id: "CRP", name: "CRP" },
  { id: "CRV", name: "CRV" },
  { id: "OUT", name: "OUT" },
];