import { createGlobalStyle } from "styled-components";
import background from "../images/banner-home.jpg";

const HomeStyle = createGlobalStyle`
  .HomeApp {
    background: url(${background})  center center no-repeat;
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
  }

  @media screen and (max-width: 600px) {
    .HomeApp {
      padding:120px 10px 0 10px;
      .container {
        flex-direction: column-reverse;
      }
    }
  }

  @media screen and (max-width: 414px) {
    .HomeApp {
      padding-top: 150px;
      .container {
        flex-direction: column-reverse;
      }
    }
  }
`;

export default HomeStyle;
