import React, { useState } from "react";
import {
  Flex,
  Box,
  Img,
  Text,
  Button,
  Divider,
  Checkbox,
} from "@chakra-ui/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import SideBar from "../../../components/SideBar";
import InternalHeader from "../../../components/InternalHeader";
import Breadcrumb from "../../../components/Breadcrumb";
import ProgressAgendamento from "../../../components/ProgressAgendamento";
import PersonLines from "../../../images/PersonLines.svg";
import IconRight from "../../../images/iconRight.svg";
import IconLeft from "../../../images/iconLeft.svg";
import TextInput from "../../../components/TextInput";
import TextSelect from "../../../components/TextSelect.js";

const schema = yup.object().shape({
  patientName: yup.string().required("O campo nome é obrigatório"),
  CPF: yup
    .string()
    .trim()
    .required("Campo obrigatório!")
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "Documento inválido"),
  noCPF: yup.boolean(),
  email: yup.string().email(),
  gender: yup.string(),
  birthDate: yup.string(),
  phone: yup.string(),
  healthiInsurance: yup.string(),
  registrationNumber: yup.string(),
  responsibleName: yup.string().when("noCPF", {
    is: true,
    then: yup.string(),
  }),
  responsibleCPF: yup.string().when("noCPF", {
    is: true,
    then: yup.string(),
  }),
  responsibleEmail: yup.string().when("noCPF", {
    is: true,
    then: yup.string(),
  }),
  responsibleGender: yup.string().when("noCPF", {
    is: true,
    then: yup.string(),
  }),
  responsibleBirthDate: yup.string().when("noCPF", {
    is: true,
    then: yup.string(),
  }),
  responsiblePhone: yup.string().when("noCPF", {
    is: true,
    then: yup.string(),
  }),
});
function NewPatientData() {
  const [noCpf, setNoCpf] = useState(false);
  const HealthInsuranceList = [
    { id: "1", name: "Convenio 1" },
    { id: "2", name: "Convenio 2" },
    { id: "3", name: "Convenio 3" },
    { id: "4", name: "convenio 4" },
    { id: "5", name: "convenio 5" },
    { id: "6", name: "convenio 6" },
  ];
  const genderList = [
    { id: "1", name: "Masculino" },
    { id: "2", name: "Feminino" },
  ];

  const {
    register,
    
    formState: { errors },
    
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  return (
    <Flex backgroundColor="#F7F8FC" minH="100vh" minW="100vw">
      <Flex width="165px" id="sidebar" className="sidebar">
        <SideBar />
      </Flex>

      <Box
        className="page"
        padding={{ base: "10px", md: "24px 50px" }}
        flexDirection={{ base: "column", md: "row" }}
        w={{
          base: `calc(100% - 165px)`,
          md: `calc(100% - 300px))`,
        }}
      >
        <InternalHeader />
        <Breadcrumb
          page="Agendamento"
          pageTitle="Agendamento de exame líquor"
        />
        <Flex
          mb="15px"
          p="60px 105px"
          border="1px solid #CED4DA"
          borderRadius="10px"
          flexDirection="column"
          minW="1089"
        >
          <ProgressAgendamento />
          <Flex gap="24px" alignItems="center" mt="42px">
            <Img src={PersonLines} w="24px" h="24px" />
            <Text fontWeight="600" fontSize="24px">
              Dados do Paciente
            </Text>
          </Flex>
          <Divider mt="32px" />
          <Flex gap="176px" marginTop="35px">
            <TextInput
              register={{ ...register("patientName") }}
              isError={errors.patientName}
              labelDescription="Nome completo do Paciente"
              InputPlaceholder="João Aparecido"
            />
          </Flex>
          <Flex marginTop="35px" alignItems="center">
            <Flex w="100%" gap="19.5px" alignItems="center">
              <Flex minW="254px">
                <TextInput
                  labelDescription="CPF"
                  maskInput={"***.***.***-**"}
                  InputPlaceholder="000.000.000-00"
                  register={{ ...register("cpf") }}
                  isError={errors.cpf}
                />
              </Flex>

              <Flex w="56%">
                <Checkbox
                  onChange={() => setNoCpf(!noCpf)}
                  // {...register("noCPF")}
                  w="100%"
                >
                  Não tem CPF
                </Checkbox>
              </Flex>
            </Flex>
            <TextInput
              labelDescription="E-mail"
              register={{ ...register("email") }}
              isError={errors.email}
              InputPlaceholder="E-mail"
            />
          </Flex>
          <Flex marginTop="35px" gap="52px" alignItems="center">
            <Flex minW="254px">
              <TextSelect
                options={genderList}
                titleOption="Selecione o sexo"
                labelDescription="Sexo"
                isError={errors.gender}
                register={{ ...register("gender") }}
              />
            </Flex>

            <TextInput
              labelDescription="Data de nascimento"
              InputPlaceholder="Data de nascimento"
              maskInput={"99/99/9999"}
              isError={errors.birthDate}
              register={{ ...register("birthDate") }}
            />
            <TextInput
              labelDescription="Telefone"
              isError={errors.phone}
              register={{ ...register("phone") }}
              InputPlaceholder="Telefone"
              maskInput="(99) 9999-9999"
            />
          </Flex>
          <Flex gap="40px" marginTop="35px" alignItems="center">
            <TextSelect
              w="454px"
              titleOption="Escolha ou digite aqui o Convênio"
              labelDescription="Convênio"
              isError={errors.healthInsurance}
              register={{ ...register("healthInsurance") }}
              options={HealthInsuranceList}
            />

            <TextInput
              maxW="190px"
              labelDescription="Número da Matricula"
              InputPlaceholder="00 0000 0000 000"
              isError={errors.registrationNumber}
              register={{ ...register("registrationNumber") }}
            />
          </Flex>
          {noCpf && (
            <Box>
              <Divider mt="32px" />
              <Flex flexDirection="column" p="28px 0 0 " gap="27px">
                <Text
                  as="strong"
                  fontWeight="700"
                  color="#F3953F"
                  fontSize="20px"
                >
                  Dados do responsável
                </Text>
                <Flex>
                  <TextInput
                    labelDescription="Nome completo do responsável"
                    InputPlaceholder="Nome completo do responsável"
                    isError={errors.responsibleName}
                    register={{ ...register("responsibleName") }}
                  />
                </Flex>
                <Flex alignItems="center" gap="192px">
                  <Flex minW="254px">
                    <TextInput
                      labelDescription="CPF do responsavel"
                      isError={errors.responsibleCPF}
                      register={{ ...register("responsibleCPF") }}
                      maskInput={"***.***.***-**"}
                      InputPlaceholder="000.000.000-00"
                      name="responsibleCPF"
                      id="responsibleCPF"
                    />
                  </Flex>
                  <TextInput
                    labelDescription="E-mail do responsavel"
                    isError={errors.responsibleEmail}
                    register={{ ...register("responsibleEmail") }}
                    InputPlaceholder="E-mail do responsavel"
                    name="responsibleEmail"
                    id="responsibleEmail"
                  />
                </Flex>

                <Flex alignItems="center" gap="60px">
                  <Flex minW="254px">
                    <TextSelect
                      options={genderList}
                      isError={errors.responsibleGender}
                      register={{ ...register("responsableGender") }}
                      titleOption="Selecione o sexo"
                      labelDescription="Sexo"
                    />
                  </Flex>
                  <TextInput
                    labelDescription="Data de Nascimento"
                    isError={errors.responsibleBirthDate}
                    register={{ ...register("responsibleBirthDate") }}
                    InputPlaceholder="00/00/0000"
                    maskInput={"99/99/9999"}
                  />
                  <TextInput
                    labelDescription="Telefone"
                    isError={errors.responsiblePhone}
                    register={{ ...register("responsiblePhone") }}
                    InputPlaceholder="Digite seu telefone"
                    maskInput="(99) 9999-9999"
                    name="phone"
                    id="phone"
                  />
                </Flex>
              </Flex>
            </Box>
          )}
          <Flex w="100%" justifyContent="space-between" mt="28px">
            <Button backgroundColor="#343A40" color="white" gap="8px">
              <Img src={IconLeft} />
              Voltar
            </Button>
            <Button backgroundColor="#F3953F" color="white" gap="8px">
              Confirmar cadastro
              <Img src={IconRight} />
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}

export default NewPatientData;
