import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import SideBar from "../../../components/SideBar";

import InternalHeader from "../../../components/InternalHeader";
import Breadcrumb from "../../../components/Breadcrumb";

import ProgressAgendamento from "../../../components/ProgressAgendamento";
import DoctorRequesting from "../../../images/DoctorRequestingIcon.svg";
import DoctorRequestingWhite from "../../../images/DoctorRequestingIconWhite.svg";
import IconRight from "../../../images/iconRight.svg";
import IconLeft from "../../../images/iconLeft.svg";

import { Flex, Box, Img, Text, Button, Divider } from "@chakra-ui/react";
import TextInput from "../../../components/TextInput";
import TextSelect from "../../../components/TextSelect";

const schema = yup.object().shape({
  CRM: yup
    .string("CRM obrigatório")
    .max(999999)
    .required("O campo CRM obrigatório"),
  uf: yup.string(),
});

function RequestingDoctor() {
  const [hasCRM] = useState(true);

  const EstadosList = [
    { id: "AC", name: "Acre" },
    { id: "AL", name: "Alagoas" },
    { id: "AP", name: "Amapá" },
    { id: "AM", name: "Amazonas" },
    { id: "BA", name: "Bahia" },
    { id: "CE", name: "Ceará" },
    { id: "DF", name: "Distrito Federal" },
    { id: "ES", name: "Espírito Santo" },
    { id: "GO", name: "Goiás" },
    { id: "MA", name: "Maranhão" },
    { id: "MT", name: "Mato Grosso" },
    { id: "MS", name: "Mato Grosso do Sul" },
    { id: "MG", name: "Minas Gerais" },
    { id: "PA", name: "Pará" },
    { id: "PB", name: "Paraíba" },
    { id: "PR", name: "Paraná" },
    { id: "PE", name: "Pernambuco" },
    { id: "PI", name: "Piauí" },
    { id: "RJ", name: "Rio de Janeiro" },
    { id: "RN", name: "Rio Grande do Norte" },
    { id: "RS", name: "Rio Grande do Sul" },
    { id: "RO", name: "Rondônia" },
    { id: "RR", name: "Roraima" },
    { id: "SC", name: "Santa Catarina" },
    { id: "SP", name: "São Paulo" },
    { id: "SE", name: "Sergipe" },
    { id: "TO", name: "Tocantins" },
  ];

  const {
    register,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  useEffect(() => {}, [hasCRM]);
  return (
    <Flex backgroundColor="#F7F8FC" minH="100vh" minW="100vw">
      <Flex width="165px" id="sidebar" className="sidebar">
        <SideBar />
      </Flex>

      <Box
        className="page"
        padding={{ base: "10px", md: "24px 50px" }}
        flexDirection={{ base: "column", md: "row" }}
        w={{
          base: `calc(100% - 165px)`,
          md: `calc(100% - 300px))`,
        }}
      >
        <InternalHeader />
        <Breadcrumb
          page="Agendamento"
          pageTitle="Agendamento de exame líquor"
        />
        <Flex
          mb="15px"
          p="60px 105px"
          border="1px solid #CED4DA"
          borderRadius="10px"
          flexDirection="column"
          minW="1089"
        >
          <ProgressAgendamento />
          <Flex gap="24px" alignItems="center" mt="42px">
            <Img src={DoctorRequesting} w="30px" h="40px" />
            <Text fontWeight="600" fontSize="24px">
              Qual o médico(a) solicitante?
            </Text>
          </Flex>
          <Divider mt="32px" />

          <Flex h="100%" m="38px 0 0" alignItems="center" gap="38px">
            <Flex w="195px">
              <TextInput
                w="100%"
                labelDescription="CRM"
                register={{ ...register("CRM") }}
                maskInput={"999999"}
                InputPlaceholder="00000"
                name="CRM"
                id="CRM"
              />
            </Flex>
            <Flex w="195px">
              <TextSelect
                isError={errors.uf}
                register={{ ...register("uf") }}
                labelDescription="UF"
                name="uf"
                id="uf"
                titleOption="Escolha o UF"
                options={EstadosList}
              />
            </Flex>

            <Button
              backgroundColor="#B9BD5C"
              color="white"
              gap="12px"
              mt="20px"
            >
              {hasCRM ? "Buscar novamente" : "Buscar"}
              <Img w="16px" src={DoctorRequestingWhite} />
            </Button>
          </Flex>
          <Divider mt="32px" />

          {hasCRM && (
            <Flex mt="48px">
              <Flex flexDirection="column" w="268px" gap="10px">
                <Text as="strong" fontSize="14px">
                  Nome Completo
                </Text>
                <Text color="#8F9194">João Carmelia Vagem</Text>
              </Flex>
              <Flex flexDirection="column" w="121px" gap="10px">
                <Text as="strong" fontSize="14px">
                  CRM
                </Text>
                <Text color="#8F9194">9890987-00</Text>
              </Flex>
              <Flex flexDirection="column" w="193px" gap="10px">
                <Text as="strong" fontSize="14px">
                  UF
                </Text>
                <Text color="#8F9194">SP</Text>
              </Flex>
            </Flex>
          )}

          <Flex w="100%" h="100%" justifyContent="space-between" mt="125px">
            <Button backgroundColor="#343A40" color="white" gap="8px">
              <Img src={IconLeft} />
              Voltar
            </Button>
            <Button backgroundColor="#F3953F" color="white" gap="8px">
              Continuar
              <Img src={IconRight} />
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}

export default RequestingDoctor;
