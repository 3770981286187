import { useState, useEffect } from "react";
import { Flex, Text, Img } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";

import { useAuth, useRegister } from "../../../hooks";
import { toast } from "react-toastify";


import { GoBackButton, RegisterButton } from "../../../components/Buttons";
import TemplateRegister from "../../../components/TemplateRegister";
import TextInput from "../../../components/TextInput";
import Loading from "../../../components/Loading";
import TextSelect from "../../../components/TextSelect";

import Paciente from "../../../images/cliente.svg";

const schema = yup.object().shape({
  validateType: yup.string(),
  hospitals: yup.string(),
  document: yup.string()
    .trim()
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "Documento inválido"),
  birthDate: yup.string().required("O campo aniversário obrigatório"),
});

export default function ValidatePatient() {
  const [loading, setLoading] = useState(false);

  const { validateUser } = useAuth();

  const { name, token, validateType } = useLocation()["state"];

  const [hospitals, setHospitals] = useState();

  const { hospitalsList } = useRegister();

  const navigate = useNavigate();

  async function getHospitals() {
    try {
      const response = await hospitalsList();
      setHospitals(response?.data);

      console.log(response?.data)
    } catch (error) {
      toast.error("Não foi possível carregar a lista de especialidades");
      console.error(error);
    }
  }
  useEffect(() => {
    getHospitals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  const submit = async (values) => {


    try {
      setLoading(true);

      Object.assign(values, { token });

      const response = await validateUser(values);

      if (response.status === 200) {
        navigate("/cadastro/paciente", {
          state: {
            token,
            document: values.document,
            birthDate: values.birthDate,
          },
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  return (
    <TemplateRegister>
      {loading && <Loading />}
      <Content>
        <Flex
          flexDirection="column"
          backgroundColor="white"
          p="20px 46px"
          borderRadius="8px"
          w="100%"
        >
          <form onSubmit={handleSubmit(submit)}>
            <Flex
              flexDirection="column"
              alignContent="center"
              alignItems="center"
              mb="77px"
              gap="10px"
            >
              <Flex w="100%" justifyContent="center" gap="1.5em">
                <Img w="42px" src={Paciente} alt="" />
                <Text
                  w="150px"
                  as="strong"
                  color="var(--orange-senne-secundary)"
                >
                  Paciente ou <br />
                  Responsável
                </Text>
              </Flex>
              <h1 fontSize="40px">Validar Cadastro</h1>
            </Flex>

            <Flex w="100%" justifyContent="center">
              <Text w="200px" as="strong" align="center">
                Olá, {name}
              </Text>
            </Flex>

            <Flex gap="10px" justifyContent="center" flexDirection="column">

              {
                validateType === "C" && (
                  <TextSelect
                    isError={errors.hospitals}
                    register={{ ...register("hospitals") }}
                    labelDescription="Hospitais"
                    name="hospitals"
                    id="hospitals"
                    titleOption="Selecione o Hospital que realizou exames"
                    options={hospitals}
                    pb="10px"
                    required
                  />         

                )
              }
   
              {
                validateType === "D" && (
                  <TextInput
                    isError={errors.document}
                    register={{ ...register("document") }}
                    labelDescription="Seu CPF"
                    InputPlaceholder="Digite seu CPF"
                    maskInput={"***.***.***-**"}
                    name="document"
                    id="document"
                    required
                  />
                )
              }

              <TextInput
                isError={errors.birthDate}
                register={{ ...register("birthDate") }}
                labelDescription="Sua data de nascimento"
                InputPlaceholder="Digite seu nascimento"
                maskInput={"**/**/****"}
                name="birthDate"
                id="birthDate"
              />

            </Flex>

            <Flex mt="50px" justifyContent="space-between" alignItems="center">
              <Link to="/login/paciente">
                <GoBackButton />
              </Link>

              <RegisterButton type="submit">Continuar</RegisterButton>
            </Flex>
          </form>
        </Flex>
      </Content>
    </TemplateRegister>
  );
}
const Content = styled.div`
  padding: 105px 0;
  color: var(--gray-dark);
  h1 {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    line-height: 36px;
    font-weight: bold;
    font-weight: 700;
  }
  h2 {
    font-size: 1rem;
    font-weight: 600;
  }
  p {
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0;
  }
  > div {
  }
  a {
    text-decoration: none;
  }
`;
