import React from "react";
import { Flex, Box, Img, Text, Button, Divider } from "@chakra-ui/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import SideBar from "../../../components/SideBar";
import InternalHeader from "../../../components/InternalHeader";
import Breadcrumb from "../../../components/Breadcrumb";
import ProgressAgendamento from "../../../components/ProgressAgendamento";
import PersonLines from "../../../images/PersonLines.svg";
import IconRight from "../../../images/iconRight.svg";
import IconLeft from "../../../images/iconLeft.svg";

import TextInput from "../../../components/TextInput";
import TextSelect from "../../../components/TextSelect.js";

const schema = yup.object().shape({
  healthInsurance: yup.string().required("O campo nome é obrigatório"),
  registrationNumber: yup
    .string()
    .email()
    .required("O campo email é obrigatório"),
});
function PatientData() {
  const HealthInsuranceList = [
    { id: "1", name: "Convenio 1" },
    { id: "2", name: "Convenio 2" },
    { id: "3", name: "Convenio 3" },
    { id: "4", name: "convenio 4" },
    { id: "5", name: "convenio 5" },
    { id: "6", name: "convenio 6" },
  ];

  const {
    register,
    
    formState: { errors },
    
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  return (
    <Flex backgroundColor="#F7F8FC" minH="100vh" minW="100vw">
      <Flex width="165px" id="sidebar" className="sidebar">
        <SideBar />
      </Flex>

      <Box
        className="page"
        padding={{ base: "10px", md: "24px 50px" }}
        flexDirection={{ base: "column", md: "row" }}
        w={{
          base: `calc(100% - 165px)`,
          md: `calc(100% - 300px))`,
        }}
      >
        <InternalHeader />
        <Breadcrumb
          page="Agendamento"
          pageTitle="Agendamento de exame líquor"
        />
        <Flex
          mb="15px"
          p="60px 105px"
          border="1px solid #CED4DA"
          borderRadius="10px"
          flexDirection="column"
          minW="1089"
        >
          <ProgressAgendamento />
          <Flex gap="24px" alignItems="center" mt="42px">
            <Img src={PersonLines} w="24px" h="24px" />
            <Text fontWeight="600" fontSize="24px">
              Dados do Paciente
            </Text>
          </Flex>
          <Divider mt="32px" />
          <Flex gap="176px" marginTop="35px">
            <Box>
              <Text as="strong">Nome completo</Text>
              <Text color="#8F9194">João Carmelia Vagem</Text>
            </Box>
            <Box>
              <Text as="strong">CPF</Text>
              <Text color="#8F9194">134134134-55</Text>
            </Box>
          </Flex>
          <Flex gap="176px" marginTop="35px">
            <Box>
              <Text as="strong">Data de nascimento</Text>
              <Text color="#8F9194">16/08/1994</Text>
            </Box>
            <Box>
              <Text as="strong">Sexo</Text>
              <Text color="#8F9194">Masculino</Text>
            </Box>
          </Flex>
          <Flex gap="176px" marginTop="35px" alignItems="center">
            <TextSelect
              titleOption="Escolha ou digite aqui o Convênio"
              labelDescription="Convênio"
              isError={errors.healthInsurance}
              register={{ ...register("cpf") }}
              options={HealthInsuranceList}
            />
            <TextInput
              labelDescription="Número da Matricula"
              InputPlaceholder="00 0000 0000 000"
              isError={errors.registrationNumber}
              register={{ ...register("cpf") }}
            />
          </Flex>

          <Flex w="100%" justifyContent="space-between" mt="150px">
            <Button backgroundColor="#343A40" color="white" gap="8px">
              <Img src={IconLeft} />
              Voltar
            </Button>
            <Button backgroundColor="#F3953F" color="white" gap="8px">
              Confirmar cadastro
              <Img src={IconRight} />
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}

export default PatientData;
