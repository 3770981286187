import styled from "styled-components";
import HeaderLogo from "./HeaderLogo";
import LoginImage from "../images/loginImage.png";

export default function TemplateLogin({ children, colorClass }) {
  return (
    <>
      <HeaderLogo />
      <BlockPrimary>
        <div className="image col-md-6">
          <img src={LoginImage} alt=""></img>
        </div>
        <div className={`content col-md-6 ${colorClass}`}>{children}</div>
      </BlockPrimary>
    </>
  );
}

const BlockPrimary = styled.div`
  min-height: calc(100vh - 103px);
  width: 100%;
  display: flex;
  .image {
    max-height: calc(100vh);
    position: relative;
    &:before {
      content: "";
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 8;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &:after {
      content: "";
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 9;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content {
    background-color: #ec6726;
    &.green {
      background-color: #b9bd5c;
    }
    &.color-senne {
      background-color: #b9bd5c;
    }
  }
  @media screen and (max-width: 800px) {
    display: block;
    .image {
      max-height: 300px;
      overflow: hidden;
    }
  }
`;
