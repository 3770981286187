import React from "react";

// import { Container } from './styles';

const ExitIcon: React.FC = () => {
  return (
    <>
      <svg
        width="27"
        height="20"
        viewBox="0 0 27 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.75 17.3125C10.75 17.528 10.8356 17.7347 10.988 17.887C11.1403 18.0394 11.347 18.125 11.5625 18.125H24.5625C24.778 18.125 24.9847 18.0394 25.137 17.887C25.2894 17.7347 25.375 17.528 25.375 17.3125V2.6875C25.375 2.47201 25.2894 2.26535 25.137 2.11298C24.9847 1.9606 24.778 1.875 24.5625 1.875H11.5625C11.347 1.875 11.1403 1.9606 10.988 2.11298C10.8356 2.26535 10.75 2.47201 10.75 2.6875V5.9375C10.75 6.15299 10.6644 6.35965 10.512 6.51202C10.3597 6.6644 10.153 6.75 9.9375 6.75C9.72201 6.75 9.51535 6.6644 9.36298 6.51202C9.2106 6.35965 9.125 6.15299 9.125 5.9375V2.6875C9.125 2.04103 9.38181 1.42105 9.83893 0.963927C10.296 0.506807 10.916 0.25 11.5625 0.25H24.5625C25.209 0.25 25.829 0.506807 26.2861 0.963927C26.7432 1.42105 27 2.04103 27 2.6875V17.3125C27 17.959 26.7432 18.579 26.2861 19.0361C25.829 19.4932 25.209 19.75 24.5625 19.75H11.5625C10.916 19.75 10.296 19.4932 9.83893 19.0361C9.38181 18.579 9.125 17.959 9.125 17.3125V14.0625C9.125 13.847 9.2106 13.6403 9.36298 13.488C9.51535 13.3356 9.72201 13.25 9.9375 13.25C10.153 13.25 10.3597 13.3356 10.512 13.488C10.6644 13.6403 10.75 13.847 10.75 14.0625V17.3125Z"
          fill="#EC6726"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.23724 10.5752C1.16157 10.4998 1.10154 10.4101 1.06058 10.3114C1.01962 10.2127 0.998535 10.1069 0.998535 9.99999C0.998535 9.89312 1.01962 9.7873 1.06058 9.68858C1.10154 9.58987 1.16157 9.50021 1.23724 9.42474L6.11224 4.54974C6.18778 4.4742 6.27746 4.41427 6.37616 4.37339C6.47487 4.3325 6.58065 4.31146 6.68749 4.31146C6.79432 4.31146 6.90011 4.3325 6.99881 4.37339C7.09751 4.41427 7.18719 4.4742 7.26274 4.54974C7.33828 4.62528 7.3982 4.71496 7.43909 4.81367C7.47997 4.91237 7.50101 5.01816 7.50101 5.12499C7.50101 5.23182 7.47997 5.33761 7.43909 5.43631C7.3982 5.53501 7.33828 5.6247 7.26274 5.70024L3.77386 9.18749H18.0625C18.278 9.18749 18.4846 9.27309 18.637 9.42547C18.7894 9.57784 18.875 9.7845 18.875 9.99999C18.875 10.2155 18.7894 10.4221 18.637 10.5745C18.4846 10.7269 18.278 10.8125 18.0625 10.8125H3.77386L7.26274 14.2997C7.33828 14.3753 7.3982 14.465 7.43909 14.5637C7.47997 14.6624 7.50101 14.7682 7.50101 14.875C7.50101 14.9818 7.47997 15.0876 7.43909 15.1863C7.3982 15.285 7.33828 15.3747 7.26274 15.4502C7.18719 15.5258 7.09751 15.5857 6.99881 15.6266C6.90011 15.6675 6.79432 15.6885 6.68749 15.6885C6.58065 15.6885 6.47487 15.6675 6.37616 15.6266C6.27746 15.5857 6.18778 15.5258 6.11224 15.4502L1.23724 10.5752Z"
          fill="#EC6726"
        />
      </svg>
    </>
  );
};

export default ExitIcon;
