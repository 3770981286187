import { PartnersContext } from "../contexts/Partners";
import { useContext } from "react";

export function usePartners() {
  const context = useContext(PartnersContext);

  if (!context) {
    throw new Error("usePartners must be used within an PartnersProvider");
  }

  return context;
}
