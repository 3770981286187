import {
  Flex,
  FormErrorMessage,
  Checkbox,
  FormControl,
  Text,
} from "@chakra-ui/react";

export default function RegisterBox({
  isError,
  register,
  children,
  onChange,
  text,
  m,
}) {
  return (
    <Flex m={m}>
      <FormControl isInvalid={isError?.message}>
        <Flex gap="5px">
          <Checkbox {...register} onChange={onChange} value=""></Checkbox>

          {children}
        </Flex>

        {isError && (
          <FormErrorMessage postion="aboslute" mt="0px">
            {isError.message}
          </FormErrorMessage>
        )}
      </FormControl>
    </Flex>
  );
}
