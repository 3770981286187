import styled from "styled-components";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select as ChakraSelect,
} from "@chakra-ui/react";

export default function SelectHospitalPopup({
  isError,
  placeholder,
  label,
  register,
  autoFocus = false,
  id,
  options,
  labelDescription = "Descrição do label",
  titleOption,
  labelFontSize,
  ...rest
}) {
  return (
    <>
      <BlockInput>
        <FormControl isInvalid={isError?.message}>
          <FormLabel fontSize={labelFontSize ?? "16px"} m="0">
            {labelDescription}
          </FormLabel>
          <ChakraSelect placeholder={placeholder} {...register} {...rest}>
            <option value="">{titleOption}</option>
            {options?.map((item) => {
              return (
                <option value={item?.uuid} key={item?.uuid}>
                  {item?.name}
                </option>
              );
            })}
          </ChakraSelect>
          {isError && <FormErrorMessage>{isError?.message}</FormErrorMessage>}
        </FormControl>
      </BlockInput>
    </>
  );
}

const BlockInput = styled.div`
  width: 400px;

  label {
    display: block;

    line-height: 15px;
    color: var(--gray-dark);
    font-weight: bold;
    width: 100%;
  }

  select {
    appearance: active;
    display: block;
    background: #ffffff;
    border: 1px solid #d2d3e5;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 5px;
    padding: 10px;
    width: 100%;
  }
`;
