import React from "react";
import { useNavigate } from "react-router-dom";
import TemplateRegister from "../../components/TemplateRegister";

import styled from "styled-components";

import TextInput from "../../components/TextInput";
import Card from "../../components/Card";
import { Flex, Box } from "@chakra-ui/react";

import { RegisterButton } from "../../components/Buttons";
import { useUsers } from "../../hooks";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Digite uma e-mail valido")
    .required("Digite seu Email"),
});

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { forgotPassword } = useUsers();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  async function submit(values) {
    try {
      const response = await forgotPassword(values);
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <TemplateRegister>
      <Content onSubmit={handleSubmit(submit)}>
        <Card>
          <Flex
            flexDirection="column"
            alignContent="center"
            alignItems="center"
            mb="25px"
          >
            <img src="images/key-fill.svg" alt="" />
            <h1>Alteração de senha</h1>
          </Flex>
          <Box>
            <TextInput
              isError={errors.email}
              labelDescription="Digite seu e-mail"
              InputPlaceholder="insira aqui seu e-mail"
              name="email"
              type="email"
              register={{ ...register("email") }}
            />
            <Flex
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              padding="0 90px"
            >
              <RegisterButton type="submit">Enviar</RegisterButton>
            </Flex>
          </Box>
        </Card>
      </Content>
    </TemplateRegister>
  );
}

const Content = styled.form`
  width: 535px;
  padding: 100px 0;
  ul {
    padding-left: 20px;
  }

  h1 {
    width: 100%;
    text-align: center;
    font-size: 36px;
    line-height: 36px;
    font-weight: bold;
  }
  p {
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0;
  }
  a {
    text-decoration: none;
  }
`;
