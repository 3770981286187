import React, { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./globals";

const breakpoints = {
  xs: "320px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
}


export const devices = {
  xs: `(min-width: ${breakpoints.xs})`,
  sm: `(min-width: ${breakpoints.sm})`,
  md: `(min-width: ${breakpoints.md})`,
  lg: `(min-width: ${breakpoints.lg})`,
  xl: `(min-width: ${breakpoints.xl})`,
  "2xl": `(min-width: ${breakpoints["2xl"]})`,
}
export const theme = {
  colors: {
    white: "#ffffff",
    black: "#000000",
    gray: "#D7D7D7",
    background: "#F7F8FC",

    text: "#252525",
    textGray: "#00000080",
    blackBtn: "#343A40",

    primary: "#F3953F",
    secondary: "#B9BD5C",
    tertiary: "#EC6726",

    success: "#198754",
    error: "#DC3545",
    warning: "#FFC107",
    info: "#0DCAF0",
  },
  fontSize: {
    xs: "0.75rem" /* 12px */,
    sm: "0.875rem" /* 14px */,
    md: "1rem" /* 16px */,
    lg: "1.125rem" /* 18px */,
    xl: "1.25rem" /* 20px */,
    "2xl": "1.5rem" /* 24px */,
    "3xl": "1.75rem" /* 28px */,
    "4xl": "2rem" /* 32px */,
    "5xl": "2.5rem" /* 40px */,
    "6xl": "3rem" /* 48px */,
    "7xl": "3.75rem" /* 60px */,
    "8xl": "4.5rem" /* 72px */,
    "9xl": "6rem" /* 96px */,
    "10xl": "8rem" /* 128px */,
  },
};

interface ThemeProps {
  children: ReactNode;
}

export const Theme: React.FC<ThemeProps> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
      <GlobalStyles />
    </ThemeProvider>
  );
};
