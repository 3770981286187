import React from "react";
import styled from "styled-components";
import Card from "./Card";

export default function CardHome({ logo, text }) {
  return (
    <>
      <Card className="flex-column align-items-center">
        <Image src={logo} alt=" " />
        <Text> {text} </Text>
      </Card>
    </>
  );
}

const Text = styled.p`
  /* width: 100%; */
  text-align: center;
  color: #b9bd5c;
  line-height: 24px;
  font-weight: bold;
`;

const Image = styled.img`
  width: 100px;
  min-width: 84px;
  min-height: 84px;
  height: 100px;
  object-fit: contain;
  max-width: 100%;
  margin-bottom: 16px;
`;
