import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SideBar from "../../components/SideBar";
import InternalHeader from "../../components/InternalHeader";
import { BreadCrumbs } from "../../components/Breadcrumb2";
import { Flex, Box, Text } from "@chakra-ui/react";
import ArrowRight from "../../images/arrowRight.svg";
import PeopleGroup from "../../images/people.svg";
import Person from "../../images/person-check.svg";
import { useGroups, useUsers, useAuth, usePartners } from "../../hooks";
import {
  OrangeSenneButton,
  PrimaryResultsButton,
} from "../../components/Buttons";

const MasterManagement = () => {
  const { user } = useAuth();
  const { getUsers } = useUsers();
  const { getGroups } = useGroups();
  const { getPartners } = usePartners();
  const [groupList, setGroupList] = useState();
  const [userList, setUserList] = useState();
  const [partnerList, setPartnerList] = useState();

  const [uuidUser, setUUIDUser] = useState();
  const [hospitalName, setHospitalName] = useState("");

  async function users_list() {
    try {
      const results = await getUsers();
      setUserList(results);
    } catch (error) {
      console.log(error);
    }
  }

  async function group_List() {
    try {
      const results = await getGroups();
      setGroupList(results?.Grupos);
    } catch (error) {
      console.log(error);
    }
  }

  async function partners_list() {
    try {
      const results = await getPartners();
      setPartnerList(results?.Partners);
    } catch (error) {
      console.error(error);
    }
  }
  

  // async function getUserPermission( defaultHospital ) {
  //   const hospitals = await getUserHospitalsList();
  //   const { permissions } = hospitals.find((hospital) => hospital.uuid === defaultHospital);
  //   setPermissions(permissions);
  // }

  useEffect(() => {
    if (uuidUser === undefined && localStorage.getItem("hospital") && user.role_id !== 1) {       
      localStorage.getItem("hospital").length > 10 ? setUUIDUser(localStorage.getItem("hospital")) : setUUIDUser(null);    
      localStorage.getItem("hospitalName") ? setHospitalName(localStorage.getItem("hospitalName")) : setHospitalName(null);
      // getUserPermission(localStorage.getItem("hospital"));
    }

    if (uuidUser === undefined && user.role_id === 1) {
      setHospitalName("Administrador Senne");
    }
    users_list();
    group_List();
    partners_list();
  }, []);
  const navigate = useNavigate();
  return (
    <Flex width="100%" minHeight="100vh" backgroundColor="var(--cinza-bg)">
      <Flex width="220px" id="sidebar" className="sidebar">
        <SideBar permissions={[1,2,3]} />
      </Flex>

      <Box
        p={{ base: "24px 16px 22px 16px", md: "24px 88px 22px 24px" }}
        w="calc(100% - 165px)"
      >
        <Flex
          alignContent="center"
          justifyContent="space-between"
          w="100%"
          flexDirection={{ base: "column", md: "row" }}
        >

          {/* <InternalHeader width="673px"  setHospital={handleOnChangeHospital} setUUIDUser={setUUIDUser} uuidUser={uuidUser} /> */}

          <InternalHeader 
              user={user}
              // showHospital={true}
              uuidUser={uuidUser ? uuidUser : localStorage.getItem("hospital")}
              hospitalName={hospitalName ? hospitalName : localStorage.getItem("hospitalName")}
            />

          <Flex
            w={{ base: "100%", md: "20%" }}
            h="30px"
            pt="40px"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          ></Flex>
        </Flex>
        <BreadCrumbs />

        <Box
          m="69px 0 0 0px"
          borderRadius="8px"
          backgroundColor="var(--cinza-bg)"
          flexDirection="row"
          maxW="100%"
        >
          <Box
            backgroundColor="white"
            border="1px solid var(--cinza-card)"
            borderRadius="10px"
            p="0 10px"
          >
            <Flex
              w="100%"
              h="100%"
              p="31px 24px 8px 24px"
              gap="10px"
              flexDirection={{ base: "column", lg: "row" }}
              justifyContent="space-around"
            >
              <Flex flexWrap="wrap" gap="20px" flexDirection="column">
                <Text
                  fontSize="2.5rem"
                  as="strong"
                  color="var(--orange-senne)"
                  mb="20px"
                >
                  Gerenciamento de Usuários
                </Text>
                <Flex flexWrap="wrap" gap="20px">
                  <Box
                    backgroundColor="white"
                    border="1px solid var(--cinza-card)"
                    borderRadius="10px"
                    p="13px 13px"
                    w="347px"
                    minW="280px"
                  >
                    <Link to="/gerenciamento-senne/gerenciamento-grupos">
                      <Flex h="89px">
                        <OrangeSenneButton>
                          <img src={PeopleGroup} alt="" />
                          <Text pl="40px">Gerenciar Grupos</Text>
                        </OrangeSenneButton>
                      </Flex>
                    </Link>
                  </Box>
                  <Box
                    backgroundColor="white"
                    border="1px solid var(--cinza-card)"
                    borderRadius="10px"
                    p="13px 13px"
                    w="347px"
                    minW="280px"
                  >
                    <Link to="/users">
                      <Flex h="89px">
                        <OrangeSenneButton onClick={() => navigate("/users")}>
                          <img src={Person} alt="" />
                          <Text pl="40px">Gerenciar usuarios</Text>
                        </OrangeSenneButton>
                      </Flex>
                    </Link>
                  </Box>
                  <Box
                    backgroundColor="white"
                    border="1px solid var(--cinza-card)"
                    borderRadius="10px"
                    p="13px 13px"
                    w="347px"
                    minW="280px"
                  >
                    <Link to="/partners">
                      <Flex h="89px">
                        <OrangeSenneButton
                          onClick={() => navigate("/partners")}
                        >
                          <img src={Person} alt="" />
                          <Text pl="40px">Aprovar parceiros</Text>
                        </OrangeSenneButton>
                      </Flex>
                    </Link>
                  </Box>
                </Flex>
                <Flex flexWrap="wrap" gap="20px" w="100%"></Flex>
              </Flex>
              <Flex
                border="1px solid var(--cinza-card)"
                borderRadius="10px"
                backgroundColor="white"
                h="100%"
                flexDirection="column"
                w="20%"
                minW="280px"
              >
                <Box p="30px 0 34px 25px" textAlign="center">
                  <Text fontSize="1.2rem" as="strong">
                    Métricas de gestão
                  </Text>
                </Box>
                <Flex
                  justifyContent="space-around"
                  flexDirection="column"
                  gap="20px"
                  p="0 24px"
                  h="100%"
                  w="100%"
                >
                  <Flex
                    border="var(--cinza-card) 1px solid"
                    p="10px 20px"
                    borderRadius="8px"
                    flexDirection="column"
                    textAlign="center"
                    color="#B9BD5C"
                    w="100%"
                    h="100%"
                    minH="115px"
                  >
                    <Text fontSize="0.75rem">Grupos cadastrados</Text>
                    <Text as="strong" color="#B9BD5C" fontSize="2.5rem">
                      {groupList?.total}
                    </Text>
                  </Flex>
                  <Flex
                    border="var(--cinza-card) 1px solid"
                    p="10px 20px"
                    borderRadius="8px"
                    flexDirection="column"
                    textAlign="center"
                    color="#095F55"
                    w="100%"
                    minH="115px"
                    h="100%"
                  >
                    <Text fontSize="0.75rem">Total de cadastros</Text>

                    <Text as="strong" fontSize="2.5rem">
                      {userList?.Users?.total}
                    </Text>
                  </Flex>
                  <Flex
                    border="var(--cinza-card) 1px solid"
                    p="10px 20px"
                    borderRadius="8px"
                    flexDirection="column"
                    textAlign="center"
                    color="#B9BD5C"
                    w="100%"
                    h="100%"
                    minH="115px"
                  >
                    <Text fontSize="0.75rem">Solicitação de parceiros</Text>
                    <Text as="strong" color="#B9BD5C" fontSize="2.5rem">
                      {partnerList?.length}
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  w="100%"
                  p=" 0 29px"
                  m="20px 0 47px"
                  borderRadius="8px"
                  justifyContent="center"
                >
                  <Flex fontSize="12px" w="225px" h="34px">
                    <PrimaryResultsButton
                      p="0 15px"
                      onClick={() => navigate("/estatisticas")}
                    >
                      Estatísticas de ações
                      <img src={ArrowRight} alt="" />
                    </PrimaryResultsButton>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default MasterManagement;
