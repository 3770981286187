import { useState } from "react";
import { Flex, Text, Img } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";

import { GoBackButton, RegisterButton } from "../../../components/Buttons";
import TemplateRegister from "../../../components/TemplateRegister";
import TextSelect from "../../../components/TextSelect";
import TextInput from "../../../components/TextInput";
import Loading from "../../../components/Loading";
import Terms from "../../../components/Term";

import { useRegister } from "../../../hooks";

import AgrePolicy from "../../../images/Política de Privacidade_PortalSenne.pdf";
import AgreTerms from "../../../images/Termo de Uso_Portal Senne.pdf";
import Labor from "../../../images/LabCar.svg";

import BrazilianStateList from "../../../data/BrazilianStateList";

const schema = yup.object().shape({
  uf: yup.string().required("O campo estado é obrigatório"),
  cep: yup.string().required("O campo CEP é obrigatório"),
  city: yup.string().required("O campo cidade é obrigatório"),
  number: yup.string().required("O campo numero é obrigatório"),
  address: yup.string().required("O campo endereço é obrigatório"),
  policy: yup
    .boolean()
    .required("leia e aceite os termos antes de prosseguir."),
});
export default function LaboratoryStepThree({ props }) {
  const [loading, setLoading] = useState(false);

  const Navigate = useNavigate();

  const { createPartner } = useRegister();

  async function submit(values) {
    try {
      setLoading(true);

      const { address, number, cep, city, uf, policy } = values;

      const values_API = {
        address: address + " " + number,
        cep,
        city,
        uf,
        policy,
        ...props,
      };

      const response = await createPartner(values_API);

      if (response.status === 200) {
        Navigate("/cadastro-solicitado-parceiros");
        return;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  function openpdf(item) {
    window.open(item, "_blank");
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  return (
    <>
      <TemplateRegister>
        {loading && <Loading />}
        <Content>
          <Flex
            flexDirection="column"
            backgroundColor="white"
            p="26px 46px"
            borderRadius="8px"
            w="100%"
          >
            <form onSubmit={handleSubmit(submit)} autoComplete="off">
              <Flex
                flexDirection="column"
                alignContent="center"
                alignItems="center"
                mb="25px"
                gap="10px"
              >
                <Flex
                  w="100%"
                  justifyContent="center"
                  gap="1.5em"
                  alignContent="center"
                  alignItems="center"
                >
                  <Img w="42px" src={Labor} alt="" />
                  <Text
                    w="150px"
                    as="strong"
                    color="var(--orange-senne-secundary)"
                  >
                    Demais
                    <br /> Parceiros
                  </Text>
                </Flex>
                <h1 fontSize="40px">Faça seu Cadastro</h1>
              </Flex>

              <Flex gap="26px" justifyContent="center" alignItems="center">
                <TextInput
                  isError={errors.cep}
                  register={{ ...register("cep") }}
                  labelDescription="Digite seu CEP"
                  InputPlaceholder="Digite seu CEP"
                  maskInput="99999-999"
                  name="cep"
                  id="cep"
                />
                <TextSelect
                  isError={errors.uf}
                  register={{ ...register("uf") }}
                  labelDescription="Estado"
                  name="uf"
                  id="uf"
                  titleOption="Escolha o UF"
                  options={BrazilianStateList}
                />
              </Flex>
              <Flex flexDirection="column">
                <TextInput
                  isError={errors.address}
                  register={{ ...register("address") }}
                  labelDescription="Qual seu endereço"
                  InputPlaceholder="Endereço"
                  name="address"
                  id="address"
                />
                <Flex gap="26px" justifyContent="center" alignItems="center">
                  <TextInput
                    isError={errors.number}
                    register={{ ...register("number") }}
                    labelDescription="Numero"
                    InputPlaceholder="0000"
                    name="number"
                    id="number"
                  />
                  <TextInput
                    isError={errors.city}
                    register={{ ...register("city") }}
                    InputPlaceholder="Nome da cidade"
                    labelDescription="Cidade"
                    name="city"
                    id="city"
                  />
                </Flex>

                <Terms
                  register={{ ...register("policy") }}
                  isError={errors.policy}
                >
                  <Text p="0">
                    Li e aceito a
                    <Link to="" onClick={() => openpdf(AgrePolicy)}>
                      <Text as="strong"> Política de Privacidade </Text>
                    </Link>
                    e os
                    <Link to="" onClick={() => openpdf(AgreTerms)}>
                      <Text as="strong"> Termos de uso</Text>
                    </Link>
                    .
                  </Text>
                </Terms>
              </Flex>

              <Flex
                mt="50px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Link to="/">
                  <GoBackButton />
                </Link>

                <RegisterButton type="submit">Continuar</RegisterButton>
              </Flex>
            </form>
          </Flex>
        </Content>
      </TemplateRegister>
    </>
  );
}
const Content = styled.div`
  padding: 105px 0;
  color: var(--gray-dark);
  h1 {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    line-height: 36px;
    font-weight: bold;
    font-weight: 700;
  }
  h2 {
    font-size: 1rem;
    font-weight: 600;
  }
  p {
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0;
  }
  > div {
  }
  a {
    text-decoration: none;
  }
`;
