import React, { useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactInputVerificationCode from "react-input-verification-code";

import { RegisterButton } from "../../components/Buttons";
import CountdownTimer from "../../components/CountdownTimer";
import TemplateRegister from "../../components/TemplateRegister";
import Loading from "../../components/Loading";

import { useAuth } from "../../hooks";
import '../../pages/login/paciente/styles.css';
import { toast } from "react-toastify";


export default function ValidateSMSPage() {
  const navigate = useNavigate();

  const [token, setToken] = useState(0);

  const { cellphone, expire_date, sentId } = useLocation()["state"];

  const [expireDate, setExpireDate] = useState(expire_date);

  const [expired, setExpired] = useState(false);
  const [loading, setLoading] = useState(false);

  const { resendSMS, validateSMS } = useAuth();

  const sendSMS = async () => {
    try {
      setLoading(true);

      if (token.length < 6) {
        return;
      }

      const obj = {
        cellphone,
        token,
        sentId
      };


      const result = await resendSMS(obj);

      setExpireDate(result.data.expire_date);
      setExpired(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const submit = async () => {
    try {
      setLoading(true);

      if (!token || token.replace(/\D/g, '').length < 6) {
        return toast.info('Preencha os 6 dígitos');
      }

      const obj = {
        cellphone,
        token,
      };

      const result = await validateSMS(obj);

      if (result.status === 200) {
        navigate("/dashboard");
      }


    } catch (error) {
      console.error(error);
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TemplateRegister>
        {loading && <Loading />}
        <Flex
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          h="calc(100vh - 103px)"
        >
          <Flex
            flexDirection="column"
            backgroundColor="white"
            p="32px 46px"
            borderRadius="8px"
          >
            <Flex
              textAlign="start"
              gap="16px"
              width="100%"
              flexDirection="column"
            >
              <Text
                fontSize="2.5rem"
                fontWeight="700"
                color="var(--orange-senne-secundary)"
                textAlign="center"
              >
                Código de Verificação
              </Text>

              <Text fontSize="0.8rem" w="540px">
                Estamos enviando um código de verificação para: {cellphone}
              </Text>
              <Text fontSize="0.8rem" w="540px">
                Digite o código de verificação enviado por SMS
              </Text>


              <Flex
                justifyContent="center"
                alignContent="center"
                alignItems="center"
              >
                <div className="custom-styles">
                  <ReactInputVerificationCode
                    length={6}
                    onCompleted={(value) => setToken(value)}
                  />
                </div>
              </Flex>
              <Flex mt="50px" justifyContent="space-between">
                <Flex>
                  {expired ? (
                    <RegisterButton
                      background="var(--cinza-card)"
                      onClick={loading ? null : () => sendSMS()}
                    >
                      Reenviar
                    </RegisterButton>
                  ) : (
                    <CountdownTimer
                      endTime={expireDate}
                      onTimeExpired={() => setExpired(true)}
                    />
                  )}
                </Flex>
                <RegisterButton
                  background="var(--orange-senne)"
                  onClick={loading ? null : () => submit()}
                >
                  Validar
                </RegisterButton>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </TemplateRegister>
    </>
  );
}
