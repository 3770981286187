import React from "react";

// import { Container } from './styles';

const CalendarSidebarIcon: React.FC = () => {
  return (
    <>
      <svg
        width="21"
        height="22"
        viewBox="0 0 21 22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.2459 9.82575C14.307 9.88961 14.3555 9.96548 14.3885 10.049C14.4216 10.1325 14.4386 10.2221 14.4386 10.3125C14.4386 10.4029 14.4216 10.4925 14.3885 10.576C14.3555 10.6595 14.307 10.7354 14.2459 10.7993L10.3083 14.9243C10.2474 14.9883 10.175 15.0391 10.0952 15.0737C10.0155 15.1084 9.93004 15.1262 9.84372 15.1262C9.75741 15.1262 9.67193 15.1084 9.59221 15.0737C9.51248 15.0391 9.44006 14.9883 9.3791 14.9243L7.41035 12.8618C7.34933 12.7978 7.30093 12.7219 7.26791 12.6384C7.23489 12.5549 7.2179 12.4654 7.2179 12.375C7.2179 12.2846 7.23489 12.1951 7.26791 12.1116C7.30093 12.0281 7.34933 11.9522 7.41035 11.8883C7.53358 11.7592 7.70071 11.6866 7.87497 11.6866C7.96126 11.6866 8.04671 11.7044 8.12643 11.739C8.20615 11.7736 8.27858 11.8243 8.3396 11.8883L9.84372 13.4654L13.3166 9.82575C13.3776 9.76173 13.45 9.71093 13.5297 9.67627C13.6094 9.64161 13.6949 9.62377 13.7812 9.62377C13.8675 9.62377 13.953 9.64161 14.0327 9.67627C14.1125 9.71093 14.1849 9.76173 14.2459 9.82575Z"
          className="icon"
        />
        <path
          d="M4.59375 0C4.7678 0 4.93472 0.0724328 5.05779 0.201364C5.18086 0.330295 5.25 0.505164 5.25 0.6875V1.375H15.75V0.6875C15.75 0.505164 15.8191 0.330295 15.9422 0.201364C16.0653 0.0724328 16.2322 0 16.4062 0C16.5803 0 16.7472 0.0724328 16.8703 0.201364C16.9934 0.330295 17.0625 0.505164 17.0625 0.6875V1.375H18.375C19.0712 1.375 19.7389 1.66473 20.2312 2.18046C20.7234 2.69618 21 3.39565 21 4.125V19.25C21 19.9793 20.7234 20.6788 20.2312 21.1945C19.7389 21.7103 19.0712 22 18.375 22H2.625C1.92881 22 1.26113 21.7103 0.768845 21.1945C0.276562 20.6788 0 19.9793 0 19.25V4.125C0 3.39565 0.276562 2.69618 0.768845 2.18046C1.26113 1.66473 1.92881 1.375 2.625 1.375H3.9375V0.6875C3.9375 0.505164 4.00664 0.330295 4.12971 0.201364C4.25278 0.0724328 4.4197 0 4.59375 0V0ZM1.3125 5.5V19.25C1.3125 19.6147 1.45078 19.9644 1.69692 20.2223C1.94306 20.4801 2.2769 20.625 2.625 20.625H18.375C18.7231 20.625 19.0569 20.4801 19.3031 20.2223C19.5492 19.9644 19.6875 19.6147 19.6875 19.25V5.5H1.3125Z"
          className="icon"
        />
      </svg>
    </>
  );
};

export default CalendarSidebarIcon;
