import React from "react";
import api from "../services/api";
import { toast } from "react-toastify";

export const PartnersContext = React.createContext({});

export const PartnersProvider = ({ children }) => {
  async function getPartners(params) {
    try {
      const response = await api.get("/list/partners", { params });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
  async function approvePartner(id, data) {
    try {
      const response = await api.post(`/approve/partner/${id}`, data);
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      return response.data;
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  }
  return (
    <PartnersContext.Provider value={{ getPartners, approvePartner }}>
      {children}
    </PartnersContext.Provider>
  );
};
